import React, { useState } from 'react';
import { Form, Input, Button, Spin, notification } from 'antd';
import { changePasswordApi } from '../../../api/user';
import { logout } from '../../../api/auth';
import { validatePassword } from '../../../utils/formValidation';
import useAuth from '../../../hooks/useAuth';

import './ChangePasswordForm.scss';

const ChangePasswordForm = () => {
    const [loading, setLoading] = useState(false);
    const { user } = useAuth();

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 0, span: 24 },
    };

    const onFinish = async (values) => {
        setLoading(true);
        const clientId =
            user[
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
            ];
        let data = values;
        data['UserId'] = clientId;

        const result = await changePasswordApi(data);

        console.log(result);

        if (result === undefined) {
            setLoading(false);

            notification['error']({
                message: 'Ocurrió un error, Intentelo mas tarde',
            });

            return false;
        }

        if (result.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });

            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1500);
            return false;
        }

        if (result.statusCode === 200) {
            setLoading(false);

            notification['success']({
                message: 'Operación exitosa',
                // description: "Revise su bandeja de entrada, por favor."
            });
        } else {
            let messageError = 'Ocurrió un error, Intentelo otra vez';

            if (result.statusCode === 409) {
                messageError = result.description;
            }

            if (result.statusCode === 400) {
                messageError = result.description;
            }

            setLoading(false);

            notification['error']({
                message: messageError,
            });
        }
    };

    return (
        <Spin tip="Cargando..." spinning={loading}>
            <Form
                {...layout}
                onFinish={onFinish}
                className="change-password-form"
            >
                <Form.Item
                    name="OldPassword"
                    label="Contraseña Anterior"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese una contraseña',
                        },
                        () => ({
                            validator(rule, value) {
                                if (validatePassword(value)) {
                                    return Promise.resolve();
                                } else if (!validatePassword(value)) {
                                    return Promise.reject(
                                        'La contrase\u00F1a nueva debe de tener m\u00E1s de 6 caracteres, por lo menos una may\u00FAscula y un car\u00E1cter especial'
                                    );
                                }
                            },
                        }),
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="NewPassword"
                    label="Contraseña Nueva"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese una contraseña',
                        },
                        () => ({
                            validator(rule, value) {
                                if (validatePassword(value)) {
                                    return Promise.resolve();
                                } else if (!validatePassword(value)) {
                                    return Promise.reject(
                                        'La contrase\u00F1a nueva debe de tener  mínimo 6 o más caracteres, por lo menos una may\u00FAscula, un número  y un car\u00E1cter especial'
                                    );
                                }
                            },
                        }),
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="ConfirmPassword"
                    label="Confirmar Contraseña"
                    dependencies={['NewPassword']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese la contraseña anterior',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (
                                    !value ||
                                    getFieldValue('NewPassword') === value
                                ) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(
                                    'Las dos contraseñas ingresadas no son iguales'
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    {...tailLayout}
                    className="reset-password-form__button"
                >
                    <Button type="primary" htmlType="submit" block>
                        Guardar
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default ChangePasswordForm;
