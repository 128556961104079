import React, { useEffect, useState } from 'react';
import {
    List,
    Skeleton,
    Input,
    Button,
    Row,
    Col,
    Divider,
    Modal as ModalAntd,
    notification,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import DescriptionOrder from '../../../components/Admin/DescriptionOrder';
import Modal from '../../../components/General/Modal';
import { getAccessTokenApi } from '../../../api/auth';
import { getCompanyIdApi, getCompanyApi } from '../../../api/company';
import { archiveOrdersApi } from '../../../api/order';
import useCompany from '../../../hooks/useCompany';
import { COMPANY_DISTANCE_MAX } from '../../../utils/constants';

const OrdersList = (props) => {
    const {
        loadingOrders,
        orders,
        clearButton,
        setReloadOrders,
        setReloadPendingOrders,
        setReloadHistorialOrders,
    } = props;
    const { setCompany } = useCompany();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [contextModal, setContextModal] = useState(false);
    const [titleModal, setTitleModal] = useState('Editar Orden');
    const [ordersShow, setOrderShow] = useState(orders);
    const { confirm } = ModalAntd;
    const { Search } = Input;
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();
    const [distanceMaxDelivery, setDistanceMaxDelivery] = useState(0);
    const [distanceBaseKM, setDistanceBaseKM] = useState(0);

    useEffect(() => {
        getCompanyApi(token, companyId).then((response) => {
            if (response !== undefined) {
                setCompany(response);
                console.log(response);
                setDistanceMaxDelivery(response.deliverryMaxDistance);
                setDistanceBaseKM(response.deliveryCostKM);
                localStorage.setItem(
                    COMPANY_DISTANCE_MAX,
                    response.deliverryMaxDistance
                );
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId, token]);

    useEffect(() => {
        setOrderShow(orders);
        return () => {
            setOrderShow([]);
        };
    }, [orders]);

    const onSearch = (value) => {
        if (value === '') {
            setOrderShow(orders);
        } else {
            function filtrarPorID(obj) {
                if ('orderNum' in obj && obj.orderNum === parseInt(value)) {
                    return true;
                } else {
                    return false;
                }
            }

            let arrPorID = orders.filter(filtrarPorID);
            setOrderShow(arrPorID);
        }
    };

    const archiveOrder = async () => {
        const data = {
            CompanyId: companyId,
        };
        const result = await archiveOrdersApi(token, data);
        console.log(result);
        if (result === undefined) {
            notification['error']({
                message: 'Ocurrió un error, Intentelo mas tarde',
            });
        } else {
            // if (result.statusCode) {
            //     if (result.statusCode === 401) {
            //         notification['error']({
            //             message: 'Usuario no autorizado.',
            //         });

            //         // setTimeout(() => {
            //         //     logout();
            //         //     window.location.reload();
            //         // }, 1500);
            //         return;
            //     }
            // }
            notification['success']({
                message: 'Ordenes eliminadas exitosamente',
            });
            setReloadHistorialOrders(true);

            // if(result.statusCode === 200){
            //     notification["success"]({
            //         message: "Ordenes eliminadas exitosamente"
            //     });
            //     setReloadHistorialOrders(true);
            // }else {
            //     notification["error"]({
            //         message: "Ocurrió un error, Intentelo otra vez."
            //     });
            // }
        }
    };

    function showDeleteConfirm() {
        confirm({
            title: '\u00BFSeguro que quieres eliminar TODAS las órdenes?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                archiveOrder();
            },
            onCancel() {},
        });
    }

    return (
        <>
            {loadingOrders && (
                <Skeleton
                    avatar
                    title={false}
                    loading={loadingOrders}
                    paragraph={{ rows: 4 }}
                    active
                ></Skeleton>
            )}
            {!loadingOrders && (
                <>
                    <Row>
                        <Col span={10}>
                            {clearButton === true && (
                                <Button
                                    type="primary"
                                    onClick={() => showDeleteConfirm()}
                                >
                                    Limpiar Registro
                                </Button>
                            )}
                        </Col>
                        <Col span={clearButton === true ? 14 : 24}>
                            <Search
                                placeholder="Número de orden"
                                onSearch={onSearch}
                                enterButton
                            />
                        </Col>
                    </Row>
                    <Divider></Divider>
                    <List
                        itemLayout="horizontal"
                        size="large"
                        pagination={{
                            onChange: (page) => {
                                // console.log(page);
                            },
                            pageSize: 3,
                        }}
                        locale={{ emptyText: 'No hay ordenes' }}
                        dataSource={ordersShow}
                        renderItem={(item) => (
                            <List.Item key={item.orderId}>
                                <List.Item.Meta
                                    description={
                                        <DescriptionOrder
                                            item={item}
                                            orders={orders}
                                            distanceBaseKM={distanceBaseKM}
                                            distanceMaxDelivery={
                                                distanceMaxDelivery
                                            }
                                            setReloadOrders={setReloadOrders}
                                            setIsModalVisible={
                                                setIsModalVisible
                                            }
                                            setContextModal={setContextModal}
                                            setTitleModal={setTitleModal}
                                            setReloadPendingOrders={
                                                setReloadPendingOrders
                                            }
                                            setReloadHistorialOrders={
                                                setReloadHistorialOrders
                                            }
                                        />
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </>
            )}

            <Modal
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                title={titleModal}
                footer={false}
                destroyOnClose
            >
                {contextModal}
            </Modal>
        </>
    );
};

export default OrdersList;
