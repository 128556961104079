import React, { useState } from 'react';
import { Form, Input, InputNumber, Button, Select, notification } from 'antd';
import { getAddressByZipcodeApi } from '../../../api/user';
import useUser from '../../../hooks/useUser';

const AddressForm = ({
    setAddressUser,
    setIsModalVisibleAddress,
    updateAddressClient,
    type = 'client',
    setAddressInput,
    setShowButtomValidAddress,
    setAddressRest,
    setDisableButton,
}) => {
    const { person, setPerson } = useUser();
    const [showAddressInputs, setShowAddressInputs] = useState(false);
    const [cityInput, setCityInput] = useState('');
    const [stateInput, setStateInput] = useState('initialState');
    const [addressApi, setAddressApi] = useState([]);
    const [addressForm, setAddressForm] = useState([]);
    const [isValidZipCode, setIsValidZipCode] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const layout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 12 },
    };

    const tailLayoutButton = {
        wrapperCol: {
            offset: 0,
            span: 24,
        },
    };

    const cancelValidAddress = () => {
        setIsValidZipCode(false);
        setShowAddressInputs(false);
    };

    const onFinish = (values) => {
        if (!isValidZipCode) {
            getAddressByZipcodeApi(values.zipcode).then((res) => {
                if (res === undefined) {
                    notification['error']({
                        message: 'No cuenta conexión a internet',
                    });
                    if (type === 'client') {
                        setIsModalVisibleAddress(false);
                    }
                    return false;
                }
                if (res.error) {
                    let message = 'Ocurrio un error, intentelo de nuevo';
                    if (res.code_error === 102) {
                        message = 'El código postal no es valido';
                    }
                    if (res.code_error === 105) {
                        message = 'No se encontro el código postal, revise la informácion.';
                    }
                    notification['error']({
                        message: message,
                    });

                    return false;
                }
                setAddressApi(res);
                setCityInput(res[0]['response'].municipio);
                setStateInput(res[0]['response'].estado);
                setAddressForm([
                    {
                        name: ['city'],
                        value: res[0]['response'].municipio,
                    },
                    {
                        name: ['state'],
                        value: res[0]['response'].estado,
                    },
                    {
                        name: ['colonia'],
                        value: res[0]['response'].asentamiento,
                    },
                ]);
                setShowAddressInputs(true);
                setIsValidZipCode(true);
            });
        } else {
            let numberInterior = '';
            if (values.interiorNumber === undefined) {
                values.interiorNumber = '';
            }
            if (values.interiorNumber !== '') {
                numberInterior = `-${values.interiorNumber}`;
            }
            const addressComplete = `${values.street} ${values.exteriorNumber}${numberInterior} ${values.colonia} ${values.city} ${values.zipcode} ${values.state} `;

            if (type === 'client') {
                const addressCompleteJSON = {
                    address: `${values.street} ${values.exteriorNumber}${numberInterior}`,
                    neighborhood: values.colonia,
                    city: values.city,
                    zipCode: values.zipcode,
                    state: values.state,
                };

                setPerson({
                    ...person,
                    address: `${values.street} ${values.exteriorNumber}${numberInterior}`,
                    city: values.city,
                    neighborhood: values.colonia,
                    zipCode: values.zipcode,
                    state: values.state,
                });

                setAddressUser(addressComplete);
                updateAddressClient(addressCompleteJSON);
                setIsModalVisibleAddress(false);
            } else if (type === 'adminPerfil') {
                let numberInterior = '';
                if (values.interiorNumber === undefined) {
                    values.interiorNumber = '';
                }
                if (values.interiorNumber !== '') {
                    numberInterior = `-${values.interiorNumber}`;
                }
                setAddressRest({
                    address: `${values.street} ${values.exteriorNumber}${numberInterior}`,
                    zipCode: values.zipcode,
                    neighborhood: values.colonia,
                    city: values.city,
                    state: values.state,
                });
                setIsModalVisibleAddress(false);
                setDisableButton(false);
            } else {
                setPerson({
                    ...person,
                    address: `${values.street} ${values.exteriorNumber}${numberInterior}`,
                    city: values.city,
                    neighborhood: values.colonia,
                    zipCode: values.zipcode,
                    state: values.state,
                });
                setAddressInput(addressComplete);
                setShowButtomValidAddress(true);
                setShowForm(false);
            }
        }
    };

    return (
        <>
            {showForm && (
                <Form
                    {...layout}
                    name="nest-messages"
                    onFinish={onFinish}
                    fields={addressForm}
                    onFieldsChange={(changedFields, allFields) => {
                        setAddressForm(allFields);
                    }}
                >
                    <Form.Item
                        name="zipcode"
                        label="Código Postal"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingrese el código postal',
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || value.toString().length === 5) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject('Por favor ingresa código postal válido');
                                },
                            }),
                        ]}
                    >
                        <InputNumber
                            disabled={showAddressInputs ? true : false}
                            placeholder="Código Postal"
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>

                    {showAddressInputs && (
                        <>
                            <Form.Item
                                name="street"
                                label="Calle"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingrese la calle',
                                    },
                                ]}
                            >
                                <Input placeholder="Calle" />
                            </Form.Item>
                            <Form.Item
                                name="exteriorNumber"
                                label="Número exterior"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingrese el número exterior',
                                    },
                                ]}
                            >
                                <Input placeholder="Número exterior" />
                            </Form.Item>
                            <Form.Item
                                name="interiorNumber"
                                label="Número interior"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Por favor ingrese el número exterior',
                                    },
                                ]}
                            >
                                <Input placeholder="Número interior" />
                            </Form.Item>

                            <Form.Item
                                label="Colonia"
                                name="colonia"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor seleccione una colonia',
                                    },
                                ]}
                            >
                                <Select placeholder="Colonia">
                                    {addressApi.map((ele, i) => {
                                        return (
                                            <Select.Option
                                                key={'col' + i}
                                                value={ele['response'].asentamiento}
                                            >
                                                {ele['response'].asentamiento}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Municipio / Ciudad"
                                name="city"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor seleccione un municipio',
                                    },
                                ]}
                            >
                                <Select placeholder="Ciudad">
                                    <Select.Option value={cityInput}>{cityInput}</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Estado"
                                name="state"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor seleccione un municipio',
                                    },
                                ]}
                            >
                                <Select placeholder="Estado">
                                    <Select.Option value={stateInput}>{stateInput}</Select.Option>
                                </Select>
                            </Form.Item>
                        </>
                    )}
                    <Form.Item {...tailLayoutButton}>
                        <Button type="primary" htmlType="submit" block>
                            {!showAddressInputs
                                ? 'Verificar'
                                : type === 'adminPerfil'
                                ? 'Aceptar dirección'
                                : 'Guardar dirección'}
                        </Button>
                    </Form.Item>
                    {showAddressInputs && (
                        <Form.Item {...tailLayoutButton}>
                            <Button
                                type="ghost"
                                className="mt-0"
                                block
                                onClick={cancelValidAddress}
                            >
                                Cancelar
                            </Button>
                        </Form.Item>
                    )}
                </Form>
            )}
        </>
    );
};

export default AddressForm;
