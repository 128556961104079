import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Form,
    Input,
    Button,
    Checkbox,
    Row,
    Col,
    Divider,
    Spin,
    notification,
} from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { ACCESS_TOKEN } from '../../../utils/constants';
import { signInApi } from '../../../api/user';
import Modal from '../../../components/General/Modal';
import ForgotPasswordForm from '../../../components/General/ForgotPasswordForm';

import './LoginForm.scss';

export default function LoginForm() {
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);

        const result = await signInApi(values);
        console.log(result);

        // return false;
        if (result === undefined) {
            setLoading(false);

            notification['error']({
                message: 'Intentelo mas tarde',
            });

            return true;
        }

        if (result.statusCode !== 200) {
            setLoading(false);

            let messageError = 'Usuario o contraseña incorrecta';

            if (result.statusCode === 409) {
                messageError = result.description;

                if (result.message === 'Confirmacion de correo.') {
                    setTimeout(() => {
                        window.location.href = '/validateEmail/' + values.email;
                    }, 1000);
                }
            }

            notification['error']({
                message: messageError,
            });

            return true;
        } else {
            form.resetFields();

            localStorage.setItem(ACCESS_TOKEN, result.result.jwtToken);

            notification['success']({
                message: 'Login Correcto',
            });

            setLoading(false);

            window.location.href = '/';
        }
    };

    return (
        <Spin tip="Cargando..." spinning={loading}>
            <Form
                className="login-form"
                name="normal_login"
                initialValues={{ rememberMe: true }}
                onFinish={onFinish}
                requiredMark={false}
                form={form}
            >
                <Form.Item
                    label="E-mail"
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: 'El correo electrónico no es válido',
                        },
                        {
                            required: true,
                            message: 'Por favor ingresa un correo electrónico',
                        },
                    ]}
                >
                    <Input
                        prefix={
                            <MailOutlined className="site-form-item-icon" />
                        }
                        placeholder="E-mail"
                    />
                </Form.Item>
                <Form.Item
                    label="Contraseña"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingresa una contraseña',
                        },
                    ]}
                >
                    <Input.Password
                        prefix={
                            <LockOutlined className="site-form-item-icon" />
                        }
                        type="password"
                        placeholder="Contraseña"
                    />
                </Form.Item>
                <Divider></Divider>
                <Form.Item className="login-form__content">
                    <Row gutter={2}>
                        <Col span={12}>
                            <Form.Item
                                name="rememberMe"
                                valuePropName="checked"
                                noStyle
                            >
                                <Checkbox>Recuérdame</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={12} className="login-form__content-right">
                            <Button
                                type="link"
                                onClick={() => setIsModalVisible(true)}
                            >
                                ¿Olvidó su contraseña?
                            </Button>
                            {/* <a className="login-form-forgot" href="/">
                                ¿Olvidó su contraseña?
                            </a> */}
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item>
                    <Link to="/registro">
                        <Button
                            danger
                            type="primary"
                            htmlType="button"
                            shape="round"
                            ghost={true}
                            size="large"
                            className="login-form-register"
                        >
                            Registrarse
                        </Button>
                    </Link>
                    <Button
                        danger
                        type="primary"
                        htmlType="submit"
                        size="large"
                        shape="round"
                        className="login-form-login"
                    >
                        Ingresar
                    </Button>
                </Form.Item>
            </Form>

            <Modal
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                title={'Recuperar Contraseña'}
                footer={false}
            >
                <ForgotPasswordForm setIsModalVisible={setIsModalVisible} />
            </Modal>
        </Spin>
    );
}
