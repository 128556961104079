import React, { useState } from 'react';
import {
    Form,
    Input,
    Select,
    InputNumber,
    Switch,
    Upload,
    Button,
    Image,
    Spin,
    message,
    notification,
    Row,
    Col,
    Checkbox,
    Divider,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import imageDefault from '../../../../assets/img/png/camara.ico';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { getCompanyIdApi } from '../../../../api/company';
import { addDishMenu } from '../../../../api/menu';
import { resizeFile } from '../../../../utils/formValidation';

import './DishForm.scss';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const layoutSwitch = {
    labelCol: {
        span: 16,
    },
    wrapperCol: {
        span: 8,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const DishForm = (props) => {
    const {
        setIsModalVisible,
        element,
        menuAdmin,
        setMenuAdmin,
        showPickUpMenu,
    } = props;
    const [unitOfMeasureCheck, setUnitOfMeasureCheck] = useState();
    const [maximum, setMaximum] = useState();
    const [minimum, setMinimum] = useState();
    const [unitOfMeasure, setUnitOfMeasure] = useState();
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();

    const [image, setImage] = useState({
        imageUrl: '',
    });

    const { imageUrl } = image;

    const { Option } = Select;

    const [filesList, setFileList] = useState({});

    const [loadingForm, setLoadingForm] = useState(false);

    const [form] = Form.useForm();

    const onFinish = async (values) => {
        window.scrollTo(0, 0);
        setLoadingForm(true);

        let formData = values;

        console.log(formData);
        if (unitOfMeasureCheck === true) {
            if (maximum <= minimum) {
                notification['error']({
                    message:
                        'La cantidad máxima debe ser mayor a la cantidad mínima',
                });
                setLoadingForm(false);
                return false;
            }
            else {
                const newUnitOfMeasure = {
                    UnitType: values.Type,
                    MinimumPurchase: minimum,
                    MaximumPurchase: maximum,
                };
                formData['unitOfMeasure'] = newUnitOfMeasure;

                console.log(unitOfMeasure, "UOMS");
            }

        }

        formData['CompanyId'] = companyId;
        formData['CategoryId'] = element.categoryId;

        let includeImage = false;

        let imageItemJSON;

        if (values.upload !== undefined) {
            includeImage = true;

            if (values.upload.length > 0) {
                let image = values.upload[0];

                const isJpgOrPng =
                    image.type === 'image/jpeg' ||
                    image.type === 'image/png' ||
                    image.type === 'image/jpg';

                if (!isJpgOrPng) {
                    notification['error']({
                        message: '¡Solo puedes subir archivos JPG/PNG/JPEG!',
                    });
                    setLoadingForm(true);
                    return false;
                }

                const isLt2M = image.size / 1000 < 250;
                let imageResize = '';
                if (!isLt2M) {
                    notification['warning']({
                        message:
                            'La imagen supera los 250kb, por lo tanto será reducida de tamaño.',
                        placement: 'bottomRight',
                        duration: 3,
                    });
                    // message.warning('¡La imagen supera los 250kb, sera reducida de tamaño.');
                    imageResize = await resizeFile(image.originFileObj);
                }

                // let posData64 = image.thumbUrl.indexOf(",")+1;
                // let posType = image.type.indexOf("/")+1;

                // formData["ImageData"] = image.thumbUrl.substr(posData64);

                let posData64;

                let posType = image.type.indexOf('/') + 1;
                if (isLt2M) {
                    posData64 = image.thumbUrl.indexOf(',') + 1;
                    formData['ImageData'] = image.thumbUrl.substr(posData64);
                } else {
                    posData64 = imageResize.indexOf(',') + 1;
                    formData['ImageData'] = imageResize.substr(posData64);
                }

                formData['Extension'] = image.type.substr(posType);

                imageItemJSON = {
                    companyId: companyId,
                    extension: image.type.substr(posType),
                    fileLocation: null,
                    fileName: `${companyId}_Item_${values.Name}`,
                    fileType: 'Image',
                    imageData: isLt2M
                        ? image.thumbUrl.substr(posData64)
                        : imageResize.substr(posData64),
                    imageId: 1,
                };
            }
        }

        const result = await addDishMenu(token, formData);
        console.log(result, "RESULT");

        if (result === undefined) {
            setLoadingForm(false);

            notification['error']({
                message: 'No cuenta conexion a internet',
            });

            return false;
        }

        if (result.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });

            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1200);

            return false;
        }

        if (result.statusCode === 200) {
            notification['success']({
                message: 'Elemento agregado exitosamente',
            });

            const found = menuAdmin.findIndex(
                (obj) => obj.groupId === element.groupId
            );
            const foundCat = menuAdmin[found].categories.findIndex(
                (cat) => cat.categoryId === element.categoryId
            );

            // let start = result.description.indexOf("producto")+9;
            // let end = result.description.indexOf(" para");
            // const idItem = result.description.slice(start, end);

            const itemsJSON = {
                categoryId: element.categoryId,
                categoryName: element.name,
                company: null,
                description: values.Description,
                itemId: parseInt(result.result.itemId),
                itemImage: includeImage ? imageItemJSON : null,
                itemImageId: includeImage ? 1 : null,
                price: values.Price,
                promotions: null,
                toGO: values.ToGO,
                // toTable: values.ToTable,
                available: values.Available,
                name: values.Name,
                priority: values.Priority,
                unitOfMeasure: result.result.unitOfMeasure,
            };
            menuAdmin[found].categories[foundCat].items.push(itemsJSON);

            function compare(a, b) {
                if (a.priority < b.priority) {
                    return -1;
                }
                if (a.priority > b.priority) {
                    return 1;
                }
                return 0;
            }

            menuAdmin[found].categories[foundCat].items.sort(compare);

            setMenuAdmin(menuAdmin);

            setLoadingForm(false);

            form.resetFields();
            setImage({
                imageUrl: null,
            });
            form.resetFields();
            setIsModalVisible(false);
        } else {
            let messageError =
                'Ocurrio un problema al crear un nuevo platillo, Intentelo otra vez';

            console.log(result);
            if (result.statusCode === 404) {
                messageError = result.description;
            }
            if (result.status === 400) {
                if (result.errors.Description) {
                    messageError =
                        'El campo DESCRIPCIÓN no debe tener mas de 140 caracteres';
                }
            }
            notification['error']({
                message: messageError,
            });

            setLoadingForm(false);
        }
    };

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function beforeUpload(file) {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.warning('¡Solo puedes subir archivos JPG/PNG!');
        }
        const isLt2M = file.size / 1000 < 250;
        if (!isLt2M) {
            message.warning(
                'La imegen debe ser menos de 250 kb para que no pierda calidad'
            );
        }
        //   return isJpgOrPng && isLt2M;
        return false;
    }

    const handleChange = (info) => {
        let fileList = [...info.fileList];

        if (Array.isArray(fileList) && fileList.length) {
            fileList = fileList.slice(-1);
            let file = fileList[0];

            getBase64(file.originFileObj, (imageUrl) =>
                setImage({
                    imageUrl: imageUrl,
                })
            );
        } else {
            setImage({
                imageUrl: null,
            });
        }
        setFileList({ fileList });
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList.slice(-1);
    };

    function Cancel() {
        form.resetFields();
        setImage({
            imageUrl: null,
        });
        setIsModalVisible(false);
    }

    return (
        <Spin tip="Guardando..." spinning={loadingForm} size="large">
            <Form
                {...layout}
                className="dish-form"
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Nombre del platillo"
                    name="Name"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese un nombre',
                        },
                    ]}
                >
                    <Input placeholder="Nombre del platillo" />
                </Form.Item>

                <Form.Item
                    label="Precio"
                    name="Price"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese el precio',
                        },
                    ]}
                >
                    <InputNumber
                        style={{
                            width: '100%',
                        }}
                        formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        placeholder="Precio"
                    />
                </Form.Item>

                <Form.Item
                    name="Description"
                    label="Descripción"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese una descripción',
                        },
                    ]}
                >
                    <Input.TextArea showCount maxLength={140} />
                </Form.Item>

                <div className="dish-form__container-image">
                    <Image
                        width={200}
                        src={imageUrl ? imageUrl : imageDefault}
                    />
                </div>

                <Form.Item
                    name="upload"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    extra="Imagen adjunta"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese una imagen',
                        },
                    ]}
                >
                    <Upload
                        name="logo"
                        beforeUpload={beforeUpload}
                        listType="picture"
                        multiple={false}
                        onChange={handleChange}
                        fileList={filesList}
                    >
                        <Button icon={<UploadOutlined />}>
                            Click para cargar la imagen
                        </Button>
                    </Upload>
                </Form.Item>

                <Row>
                    {showPickUpMenu && (
                        <Col span={12} xs={12} xl={12}>
                            <Form.Item
                                name="ToGO"
                                label="Menú para llevar"
                                valuePropName="checked"
                                {...layoutSwitch}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    )}
                    {/* <Col span={12} xs={12} xl={24}>
                    <Form.Item name="ToTable" label="Menú a la mesa" valuePropName="checked">
                        <Switch />
                    </Form.Item>
                </Col> */}
                    <Col span={12} xs={12} xl={12}>
                        <Form.Item
                            name="Available"
                            label="Disponible"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    name="Priority"
                    label="Prioridad"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor seleccione una prioridad',
                        },
                    ]}
                >
                    <Select placeholder="Seleccione una prioridad" allowClear>
                        <Option value={1}>1</Option>
                        <Option value={2}>2</Option>
                        <Option value={3}>3</Option>
                        <Option value={4}>4</Option>
                        <Option value={5}>5</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Unidad de medida"
                    name="unitOfMeasureCheck"
                    valuePropName="checked"
                    value={unitOfMeasureCheck}
                >
                    <Checkbox onChange={(e) => setUnitOfMeasureCheck(e.target.checked)} />
                </Form.Item>
                {unitOfMeasureCheck && (
                    <>
                        <Form.Item
                            name="Type"
                            label="Tipo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor seleccione un tipo',
                                },
                            ]}
                        >
                            <Select placeholder="Seleccione un tipo" allowClear>
                                <Option name="kg" value={1}>Kilogramo</Option>
                                <Option value={2}>Gramo</Option>
                                <Option value={3}>Litro</Option>
                                <Option value={4}>Mililitro</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Mínimo"
                            name="minimum"
                            rules={[
                                {
                                    required: true,
                                    message: "Por favor ingrese la cantidad mínima",
                                },
                            ]}
                        >
                            <InputNumber
                                style={{
                                    width: "100%",
                                }}
                                formatter={(value) =>
                                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                placeholder="2"
                                value={minimum}
                                onChange={(value) => setMinimum(value)}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Máximo"
                            name="maximum"
                            rules={[
                                {
                                    required: true,
                                    message: "Por favor ingrese la cantidad máxima",
                                },
                            ]}
                        >
                            <InputNumber
                                style={{
                                    width: "100%",
                                }}
                                formatter={(value) =>
                                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                placeholder="2"
                                value={maximum}
                                onChange={(value) => setMaximum(value)}
                            />
                        </Form.Item>
                    </>
                )}
                <Divider></Divider>

                <Row>
                    <Col span={12}>
                        <Button type="ghost" onClick={Cancel}>
                            Cancelar
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default DishForm;
