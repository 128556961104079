import React, {useState} from 'react'
import { Form, Input, Button, Typography, Divider, Row, Col, Spin, Select, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getAccessTokenApi } from '../../../api/auth';
import { getCompanyIdApi } from '../../../api/company';
import { editOrderStatus } from '../../../api/order';

import './CancelOrderForm.scss';

const CancelOrderForm = (props) => {
    
    const { setIsModalVisible, order, setreloadPage, history } = props;
    const { TextArea } = Input;
    const { Title } = Typography;
    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
    };
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();

    const [showOtherReason, setShowOtherReason] = useState(false);
    const [loading, setLoading] = useState(false);


    const onFinish = async (values) => {
        setLoading(true);

        let reasoncancelation = values.reason;

        if(reasoncancelation === 'Otra'){
            reasoncancelation = values.otherReason;
            if(reasoncancelation === "" || reasoncancelation === " "){
                notification["error"]({
                    message: "Debe escribir una razón"
                });
                setLoading(false);
    
                return false;
            }
        }

        let data ={
            orderId: order.orderId,
            status: 7,
            cancelationReason: reasoncancelation,
            companyId: companyId,
            fromClient: true
        }
        
        const result = await editOrderStatus(token,data);

        if(result === undefined){

            notification["error"]({
                message: "No cuenta conexión a internet"
            });
            setLoading(false);
            return false;
        }

        if(result.statusCode === 200){
            setLoading(false);

            notification["success"]({
                message: "Orden cancelada exitosamente"
            });

            history.push("/");
        }else{

            let message = "Ocurrió un problema, Intentelo mas tarde";
            if(result.statusCode === 404){
                message = 'Una vez aceptada, para cancelar contacté con el establecimiento';

                setreloadPage(true);
            }
            notification["error"]({
                message: message
            });
            setLoading(false);

        }


    };

    const handleReason = (value) => {
        if(value === 'Otra'){
            setShowOtherReason(true);
        }else{
            if(showOtherReason){
                setShowOtherReason(false);
            }
        }
    }

    const Cancel = () => {
        setIsModalVisible(false);
    }

    return (
        <Spin spinning={loading} tip="Cancelando...">
        <Form
        {...layout}
        name="cancelOrder"
        onFinish={onFinish}
        >
            <Row>
                <Col span={4} className="text-center">
                    <ExclamationCircleOutlined style={{ fontSize: '28px', color: '#faad14' }}/>
                </Col>
                <Col span={20}>
                    <Title level={4}>¿Estás seguro de cancelar esta orden?</Title>
                </Col>
            </Row>

            <Form.Item 
                label="Razón"
                name="reason"
                dependencies={['otherReason']}
                rules={[{ required: true, message: 'Por favor indique la razón o motivo.' }]}
            >
                <Select onChange={handleReason}>
                    <Select.Option value="Dirección equivocada">Dirección equivocada</Select.Option>
                    <Select.Option value="Precio">Precio</Select.Option>
                    <Select.Option value="Otra">Otra</Select.Option>
                </Select>
            </Form.Item>

            {showOtherReason && <Form.Item
                label="Otra razón"
                name="otherReason"
                dependencies={['reason']}
                rules={[
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (getFieldValue('reason') === "Otra" && value !== undefined) {
                                return Promise.resolve();
                            }

                        return Promise.reject('Debe escribir una razón');
                        },
                    }),
                ]}
            >
                <TextArea
                    placeholder="Razón"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    showCount 
                    maxLength={100}
                    />
            </Form.Item>}

            <Divider></Divider>

            <Row>
                <Col span={12}>
                    <Button type="ghost" onClick={Cancel}>
                        No
                    </Button>
                </Col>
                <Col span={12} className="text-right">
                    <Form.Item>
                    <Button type="primary" danger htmlType="submit">
                        Si
                    </Button>
                </Form.Item>
                </Col>
            </Row>
        </Form>
        </Spin>

    )
}

export default CancelOrderForm;
