import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout, Tabs } from 'antd';
import { DollarCircleOutlined, BarChartOutlined } from '@ant-design/icons';
import HeaderTop from '../../../components/General/HeaderTop';
import SellTable from '../../../components/Admin/SellTable';
import TopSelling from '../../../components/Admin/TopSelling';
import AuthAdminForm from '../../../components/Admin/AuthAdminForm';
import useAuth from '../../../hooks/useAuth';
import AdminHome from '../../../pages/Admin/Perfil';

import './Report.scss';

const Report = () => {
    const { user } = useAuth();
    const { Content } = Layout;
    const { TabPane } = Tabs;
    const [isAuth, setIsAuth] = useState(false);
    const [roleUser, setRoleUser] = useState('');

    useEffect(() => {
        const roleId =
            user[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
        setRoleUser(roleId);
    }, [user]);

    if (roleUser === 'Operator') {
        return (
            <>
                <Route path="/admin" component={AdminHome} />
                <Redirect to="/admin" />
            </>
        );
    }

    return (
        <Layout className="report">
            <Content className="report__content">
                <HeaderTop
                    title={'Reportes'}
                    backIcon={true}
                    backUrl="/admin"
                />
                {/* En la version Plus quitar el isAuth */}
                {roleUser === 'Admin' && isAuth && (
                    <Tabs defaultActiveKey="1" centered className="report-tabs">
                        <TabPane
                            tab={
                                <span className="report-tabs__title">
                                    <DollarCircleOutlined />
                                    Control de Ventas
                                </span>
                            }
                            key="1"
                        >
                            <SellTable />
                        </TabPane>
                        <TabPane
                            tab={
                                <span className="report-tabs__title">
                                    <BarChartOutlined />
                                    Ranking
                                </span>
                            }
                            key="2"
                        >
                            <TopSelling allRest={false}></TopSelling>
                        </TabPane>
                    </Tabs>
                )}

                {!isAuth && (
                    <div className="all-report__container">
                        <AuthAdminForm setIsAuth={setIsAuth} />
                    </div>
                )}
            </Content>
        </Layout>
    );
};

export default Report;
