import React, { useState, useEffect, useMemo } from 'react';
import {
    Form,
    Typography,
    List,
    Avatar,
    Divider,
    Button,
    Alert,
    // InputNumber,
    message,
    notification,
} from 'antd';
import {
    RightOutlined,
    EditOutlined,
    // BorderlessTableOutlined,
    // InfoCircleOutlined,
    EnvironmentOutlined,
    CaretDownOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import delivery from '../../../assets/img/png/delivery.webp';
import shop from '../../../assets/img/png/bag.png';
import Modal from '../../../components/General/Modal';
import DeliveryCostForm from '../../../components/Client/DeliveryCostForm';
import AddressForm from '../../../components/Client/AddressForm';
import { getAccessTokenApi } from '../../../api/auth';
import { updateUserApi } from '../../../api/user';
// import table from '../../../assets/img/png/table.svg';
import useUser from '../../../hooks/useUser';
import { getFullAddress } from '../../../utils/general';
import { citiesMTY } from '../../../utils/validCitiesMTY';

import './TypeOrder.scss';

const TypeOrder = (props) => {
    const [form] = Form.useForm();
    const {
        history,
        infoCompany,
        // addressClient,
        nameClient,
        phoneNumberClient,
        user,
        scheduleCompany,
    } = props;
    const { Paragraph } = Typography;
    const option = [
        {
            id: 1,
            name: 'Para Llevar',
            image: shop,
        },
        {
            id: 2,
            name: 'A Domicilio',
            image: delivery,
        },
        // {
        //     id: 3,
        //     name: "Para Comer Aquí",
        //     image: table
        // },
    ];

    // const [tableReq, setTableReq] = useState(false);
    // const [typeForm, setTypeForm] = useState([]);
    const [isModalVisibleCost, setIsModalVisibleCost] = useState(false);
    const [isModalVisibleAddress, setIsModalVisibleAddress] = useState(false);
    const [contentModalCost, setContentModalCost] = useState('');
    const { person } = useUser();
    const fullAddress = useMemo(() => getFullAddress(person), [person]);
    const [addressUser, setAddressUser] = useState(fullAddress);
    const [disableButton, setdisableButton] = useState(infoCompany === null ? true : false);
    const [isAddressNull, setIsAddressNull] = useState(addressUser === null ? true : false);
    const dayNumber = moment().day() === 0 ? 7 : moment().day();
    const [showCloseRest, setShowCloseRest] = useState(false);
    const [messageCloseRest, setMessageCloseRest] = useState('');

    useEffect(() => {
        if (scheduleCompany !== null) {
            if (scheduleCompany.Schedule.length === 0) {
                let msg = `El restaurante esta temporalmente cerrado.`;
                setMessageCloseRest(msg);
                setShowCloseRest(true);
                return false;
            }

            const validateSchedule = validSchedule();

            if (validateSchedule.isClose) {
                let msg = `Lo sentimos el establecimiento esta cerrado este día. ${validateSchedule.message}`;
                setMessageCloseRest(msg);
                setShowCloseRest(true);
                return false;
            }

            if (!validateSchedule.isClose && !validateSchedule.isBetween) {
                let msg = `Lo sentimos el establecimiento esta cerrado.  ${validateSchedule.message}`;
                setMessageCloseRest(msg);
                setShowCloseRest(true);
                return false;
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduleCompany]);

    useEffect(() => {
        setdisableButton(infoCompany === null ? true : false);
    }, [infoCompany]);

    useEffect(() => {
        if (person) {
            if (!person.city || !person.state) {
                notification['info']({
                    message: 'Necesitamos conocer tu dirección.',
                });

                setIsModalVisibleAddress(true);
            }
        }
    }, [person]);

    //ACTUALIZA DIRECCION DEL CLIENTE
    const updateAddressClient = async ({ address, neighborhood, city, zipCode, state }) => {
        if (
            address !== undefined ||
            neighborhood !== undefined ||
            city !== undefined ||
            zipCode !== undefined ||
            state !== undefined
        ) {
            if (addressUser !== `${address} ${neighborhood} ${city} ${zipCode} ${state} `) {
                console.log('actualizare');
                console.log(state);

                const token = getAccessTokenApi();

                const data = {
                    Id:
                        user[
                            'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
                        ],
                    Name: nameClient,
                    UserType: 3,
                    Address: address,
                    Neighborhood: neighborhood,
                    city: city,
                    ZipCode: zipCode.toString(),
                    State: state,
                    Email: user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
                    PhoneNumber: phoneNumberClient,
                };

                let validAddress = true;

                if (state !== infoCompany.State) {
                    validAddress = false;
                }
                const result = await updateUserApi(token, data);

                if (result === undefined) {
                    message.error('Ocurrió un error al guardar su dirección, Intentelo mas tarde');
                } else {
                    if (result.statusCode === 200) {
                        if (validAddress) {
                            message.success('Se ha actualizado su dirección');
                        } else {
                            message.error('Su ubicación no se encuentra cerca del restaurante');
                        }

                        if (isAddressNull === true) {
                            setIsAddressNull(false);
                        }
                    } else {
                        message.error('Ocurrió un error al guardar su dirección');
                    }
                }
            }
        }
    };

    const selectType = (type) => {
        if (!person.city || !person.state) {
            notification['info']({
                message: 'Necesitamos conocer tu dirección.',
            });

            setIsModalVisibleAddress(true);
            return;
        }

        // if ((!person.city || !person.state) && type === 2) {
        //     notification['info']({
        //         message: 'Necesitamos conocer tu dirección de entrega.',
        //     });

        //     setIsModalVisibleAddress(true);
        //     return;
        // }

        if (!infoCompany.State || !infoCompany.City) {
            notification['warning']({
                message:
                    'Tan pronto el establecimiento configure su dirección, usted podrá realizar su pedido',
            });

            setTimeout(() => {
                setIsModalVisibleAddress(true);
            }, 3500);

            return;
        }

        if (checkIsSoFar()) {
            notification['warning']({
                message:
                    ' Al parecer esta lejos de nuestros servicios, por favor actualice su dirección actual.',
            });

            setTimeout(() => {
                setIsModalVisibleAddress(true);
            }, 3500);

            return;
        }

        if (type === 2) {
            if (infoCompany.Delivery === false) {
                message.error('No se encuentra disponible el servicio a domicilio', 4);
                return;
            }

            // setTableReq(false);
        } else if (type === 3) {
            if (infoCompany.ToTable === false) {
                message.error('No se encuentra disponible el servicio para comer aquí', 4);
                return;
            }
            // setTableReq(true);
        } else {
            if (infoCompany.PickUp === false) {
                message.error('No se encuentra disponible el servicio para llevar', 4);
                return;
            }
            // setTableReq(false);
        }

        form.validateFields()
            .then(async (values) => {
                if (type === 2) {
                    //en pausa
                    if (isAddressNull === true) {
                        // setIsAddressNull(false);
                        // PEDIRA DICRCCION AL FINAL
                    }

                    if (isAddressNull !== true) {
                        setContentModalCost(
                            <DeliveryCostForm
                                setIsModalVisibleCost={setIsModalVisibleCost}
                                address={addressUser}
                                infoCompany={infoCompany}
                                scheduleCompany={scheduleCompany}
                                history={history}
                                updateAddressClient={updateAddressClient}
                            />
                        );
                        setIsModalVisibleCost(true);
                    } else {
                        // ver menu sin direccion
                        history.push({
                            pathname: '/menu',
                            state: {
                                OrderType: 2,
                                ClientAddress: '',
                                TableNumber: 0,
                                DistanceKM: 0,
                                DeliveryCost: 0,
                                InfoCompany: infoCompany,
                                ScheduleCompany: scheduleCompany,
                            },
                        });
                    }
                } else {
                    history.push({
                        pathname: '/menu',
                        state: {
                            OrderType: type,
                            ClientAddress:
                                values['location'] === undefined ? '' : values['location'],
                            TableNumber: values['table'] !== undefined ? values['table'] : 0,
                            InfoCompany: infoCompany,
                            ScheduleCompany: scheduleCompany,
                        }, // your data array of objects
                    });
                }
            })
            .catch((errorInfo) => {});
    };

    const handleSelectType = (id) => {
        if (!disableButton) {
            selectType(id);
        }
    };

    const validSchedule = () => {
        let now = moment().format('YYYY-MM-DD');
        const dayFound = scheduleCompany.Schedule.find(
            (element) => element.dayOfWeek === dayNumber
        );

        // let nextDay = 0;
        let response = {
            isClose: true,
            isBetween: false,
            nextDay: 0,
            message: '',
        };

        if (dayNumber >= 1 && dayNumber <= 6) {
            response.nextDay = dayNumber + 1;
        } else {
            response.nextDay = 1;
        }

        if (dayFound !== undefined) {
            //is open
            let timeDay = {
                timeStart: moment(`${now} ${dayFound.timeStart}`),
                timeEnd: moment(`${now} ${dayFound.timeEnd}`),
            };

            if (moment(timeDay.timeEnd).isBefore(timeDay.timeStart)) {
                //LA HORA FINAL ES ANTES QUE LA FINAL
                //SUMARLE UN DIA
                timeDay.timeEnd = timeDay.timeEnd.add(1, 'd');

                //CHECCAR SI LE TIENE QUE RESTAR UN DIA AL START
                // if(moment().isSameOrAfter(moment().hour(0))){
                //     timeDay.timeStart = timeDay.timeStart.subtract(1, 'd');
                // }

                // console.log(timeDay);
            }

            // console.log("empieza");
            // console.log(moment(timeDay.timeStart));
            // console.log("terminado");
            // console.log(moment(timeDay.timeEnd));

            let isBetween = moment().isBetween(timeDay.timeStart, timeDay.timeEnd);

            response.isClose = false;
            response.isBetween = isBetween;

            if (!isBetween) {
                const isBeforeStart = moment().isBefore(timeDay.timeStart);

                if (isBeforeStart) {
                    response.message = `Apertura: ${timeDay.timeStart.format(
                        'h:mm A'
                    )} a ${timeDay.timeEnd.format('h:mm A')} `;
                } else {
                    response = NextOpening(response, now);
                }
            }
        } else {
            //is not open this day
            response = NextOpening(response, now);
        }

        return response;
    };

    const NextOpening = (response, now) => {
        let findNextDay = false;

        while (!findNextDay) {
            const dayNextFound = scheduleCompany.Schedule.find(
                (element) => element.dayOfWeek === response.nextDay
            );

            if (dayNextFound === undefined) {
                if (response.nextDay >= 1 && response.nextDay <= 6) {
                    response.nextDay = response.nextDay + 1;
                } else {
                    response.nextDay = 1;
                }
            } else {
                response.nextDayTimeStart = moment(`${now} ${dayNextFound.timeStart}`);
                response.nextDayTimeEnd = moment(`${now} ${dayNextFound.timeStart}`);

                let dayString = '';
                switch (dayNextFound.dayOfWeek) {
                    case 1:
                        dayString = 'Lunes';
                        break;
                    case 2:
                        dayString = 'Martes';
                        break;
                    case 3:
                        dayString = 'Miércoles';
                        break;
                    case 4:
                        dayString = 'Jueves';
                        break;
                    case 5:
                        dayString = 'Viernes';
                        break;
                    case 6:
                        dayString = 'Sábado';
                        break;
                    case 7:
                        dayString = 'Domingo';
                        break;

                    default:
                        break;
                }
                response.message = `Próxima apertura: ${dayString} de ${moment(
                    `${now} ${dayNextFound.timeStart}`
                ).format('h:mm A')} a ${moment(`${now} ${dayNextFound.timeEnd}`).format(
                    'h:mm A'
                )} `;
                findNextDay = true;
            }
        }

        return response;
    };

    const checkIsSoFar = () => {
        let isSoFarAway = false;

        if (infoCompany) {
            if (!infoCompany.State) {
                //El RESTAURANTE no tiene configurado el ESTADO (state).
                isSoFarAway = true;
            } else if (!person.state || !person.city) {
                //El CLIENTE no tiene configurado bien su direccion.
                isSoFarAway = true;
            } else {
                // Ambos (RESTAURANTE y CLIENTE ) tienen configurado sus direcciones :)

                if (infoCompany.State !== person.state) {
                    // El CLIENTE no se encuentra en el MISMO ESTADO que el RESTAURANTE.
                    isSoFarAway = true;
                } else {
                    //Checar si el CLIENTE se encuentra en la CIUDAD o ZONA METROPOLITANADA de MTY.
                    if (person.state === 'Nuevo León') {
                        const found = citiesMTY.find((element) => element === person.city);

                        if (found) {
                            // El CLIENTE se encuentra dentro de la ZONA METROPOLITANA de MTY pero si esta en MTY.
                            isSoFarAway = false;
                        } else {
                            // El CLIENTE "NO" se encuentra dentro de la ZONA METROPOLITANA de MTY pero si esta en MTY.

                            if (person.city === infoCompany.City) {
                                // El CLIENTE se encuentra en la MISMA de la CIUDAD del RESTAURANTE en NUEVO LEON.
                                isSoFarAway = false;
                            } else {
                                // El CLIENTE "NO" se encuentra en la MISMA de la CIUDAD del RESTAURANTE en NUEVO LEON.
                                isSoFarAway = true;
                            }
                        }
                    } else {
                        //Checar si el CLIENTE esta en la misma ciudad que el restaurante QUE NO SEA MTY.

                        if (!infoCompany.City) {
                            //El RESTAURANTE "NO" ha configurado la ciudad en su ubicacion.
                            isSoFarAway = true;
                        } else {
                            if (infoCompany.City === person.city) {
                                // El CLIENTE se encuentra en la MISMA de la CIUDAD del RESTAURANTE.
                                isSoFarAway = false;
                            } else {
                                // El CLIENTE "NO" se encuentra en la MISMA de la CIUDAD del RESTAURANTE.
                                isSoFarAway = true;
                            }
                        }
                    }
                }
            }
        } else {
            isSoFarAway = true;
        }

        return isSoFarAway;
    };

    return (
        <Form
            requiredMark={false}
            className="type-order"
            form={form}
            // fields={typeForm}
        >
            {infoCompany !== null ? (
                infoCompany.ActiveOrder && (
                    <Alert message="Usted cuenta con una orden activa" type="info" showIcon />
                )
            ) : (
                <></>
            )}

            <Button
                icon={<EditOutlined />}
                type="link"
                size="large"
                block
                onClick={() => setIsModalVisibleAddress(true)}
            >
                {isAddressNull ? 'Agregar dirección' : 'Modificar dirección'}

                <CaretDownOutlined />
            </Button>

            {!isAddressNull && (
                <>
                    <Divider>Entregar en</Divider>
                    <Paragraph className="text-center">
                        {' '}
                        <EnvironmentOutlined /> {addressUser}
                    </Paragraph>
                </>
            )}

            {/* <Form.Item
                label="Calle No.Exterior, Colonia, Municipio"
                name="location"
                rules={[
                {
                    required: locationReq,
                    message: 'Por favor ingrese una ubicación',
                },
                ]}
                tooltip={{ title: 'Debe ingresar la dirección en ese orden', icon: <InfoCircleOutlined /> }}
            >
                <Input prefix={<AimOutlined />} placeholder="Calle No.Exterior, Colonia, Municipio" />
            </Form.Item> */}

            {/* <Form.Item
                label="Mesa"
                name="table"
                rules={[
                {
                    required: tableReq,
                    message: 'Por favor ingrese una mesa',
                },
                ]}
            >
                <InputNumber 
                    style={{
                        width: '100%',
                    }} 
                    prefix={<BorderlessTableOutlined />} 
                    placeholder="Mesa" />
            </Form.Item> */}

            <Divider></Divider>

            <List
                dataSource={option}
                renderItem={(item) => (
                    <List.Item key={item.id} onClick={() => handleSelectType(item.id)}>
                        <List.Item.Meta
                            avatar={<Avatar shape="square" size="large" src={item.image} />}
                            title={item.name}
                        />
                        <div>
                            <Button type="text" disabled={disableButton}>
                                <RightOutlined />
                            </Button>
                        </div>
                    </List.Item>
                )}
            ></List>

            <Divider></Divider>

            {showCloseRest && <Alert message={messageCloseRest} banner />}

            <Modal
                isVisible={isModalVisibleCost}
                setIsVisible={setIsModalVisibleCost}
                title={'Costo de Envío'}
                footer={false}
                destroyOnClose
            >
                {contentModalCost}
            </Modal>

            <Modal
                isVisible={isModalVisibleAddress}
                setIsVisible={setIsModalVisibleAddress}
                title={'Dirección de entrega'}
                footer={false}
                destroyOnClose
            >
                <AddressForm
                    updateAddressClient={updateAddressClient}
                    setAddressUser={setAddressUser}
                    setIsModalVisibleAddress={setIsModalVisibleAddress}
                />
            </Modal>
        </Form>
    );
};

export default TypeOrder;
