import { basePath } from './config';
import { COMPANY_ID, COMPANY_IMAGE, COMPANY_NAME } from '../utils/constants';

export function getCompanyIdApi() {
    const companyId = localStorage.getItem(COMPANY_ID);

    if (!companyId || companyId === 'null' || companyId === null) {
        return null;
    }

    return companyId;
}

export function getCompanyNameApi() {
    const companyName = localStorage.getItem(COMPANY_NAME);

    if (!companyName || companyName === 'null' || companyName === null) {
        return null;
    }

    return companyName;
}

export function getCompanyImageApi() {
    const companyImage = localStorage.getItem(COMPANY_IMAGE);

    if (!companyImage || companyImage === 'null' || companyImage === null) {
        return null;
    }

    return companyImage;
}

export function getCompanyApi(token, idCompany) {
    const url = `${basePath}/Companies/GetCompany`;

    if (!idCompany) {
        let promResuelta = Promise.resolve({
            error: true,
        });
        return promResuelta;
    }

    const data = {
        CompanyId: idCompany,
    };

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function getCompanySchedule(token, idCompany) {
    const url = `${basePath}/Companies/GetCompanySchedule`;

    if (!idCompany) {
        let promResuelta = Promise.resolve({
            error: true,
        });
        return promResuelta;
    }

    const data = {
        CompanyId: idCompany,
    };

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function updateCompanyApi(token, company) {
    const url = `${basePath}/Companies/EditCompany`;

    const params = {
        method: 'POST',
        body: JSON.stringify(company),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function updateLogoCompanyApi(token, data) {
    const url = `${basePath}/Companies/AddImageToCompany`;

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function addEditScheduleListToCompany(token, company) {
    const url = `${basePath}/Companies/AddEditScheduleListToCompany`;

    const params = {
        method: 'POST',
        body: JSON.stringify(company),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function deleteCompanySchedule(token, company) {
    const url = `${basePath}/Companies/DeleteCompanySchedule`;

    const params = {
        method: 'DELETE',
        body: JSON.stringify(company),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function deleteAllCompanySchedule(token, company) {
    const url = `${basePath}/Companies/DeleteAllCompanySchedule`;

    const params = {
        method: 'DELETE',
        body: JSON.stringify(company),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function createStripeAccountApi(token, idCompany) {
    const url = `${basePath}/Companies/CreateStripeAccount`;

    if (!idCompany) {
        let promResuelta = Promise.resolve({
            error: true,
        });
        return promResuelta;
    }

    const data = {
        CompanyId: idCompany,
    };

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function createStripeAccountLinkApi(token, idCompany) {
    const url = `${basePath}/Companies/CreateStripeAccountLink`;

    if (!idCompany) {
        let promResuelta = Promise.resolve({
            error: true,
        });
        return promResuelta;
    }

    const data = {
        CompanyId: idCompany,
    };

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function processStripeAccountApi(token, idCompany) {
    const url = `${basePath}/Companies/ProcessStripeAccount`;

    if (!idCompany) {
        let promResuelta = Promise.resolve({
            error: true,
        });
        return promResuelta;
    }

    const data = {
        CompanyId: idCompany,
    };

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function createStripePaymentIntent(token, orderPayment) {
    const url = `${basePath}/Companies/CreateStripePaymentIntent`;

    const params = {
        method: 'POST',
        body: JSON.stringify(orderPayment),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function createStripeSetupIntentApi(token, idCompany) {
    const url = `${basePath}/Companies/CreateStripeSetupIntent`;

    if (!idCompany) {
        let promResuelta = Promise.resolve({
            error: true,
        });
        return promResuelta;
    }

    const data = {
        CompanyId: idCompany,
    };

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function getStripePaymentMethodsApi(token) {
    const url = `${basePath}/Companies/GetStripePaymentMethods`;

    const params = {
        method: 'POST',
        body: JSON.stringify({}),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function getCompaniesApi(token, isTestingCompany) {
    const url = `${basePath}/Companies/GetCompanies?isTestingCompany=${isTestingCompany}`;

    const params = {
        method: 'GET',
        // body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function getCategoriesApi(token) {
    const url = `${basePath}/CompanyCategories`;

    const params = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function changeActiveStatusApi(token, companyId, status) {
    const url = `${basePath}/Companies/${companyId}/ChangeActiveStatus`;

    const company = {
        Active: status,
    };
    const params = {
        method: 'PUT',
        body: JSON.stringify(company),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            console.log(response);
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}
