import React, { useState, createContext } from 'react';

export const OrderContext = createContext(null);

export default function OrderProvider(props) {
    const { children } = props;

    const [orderGeneral, setOrderGeneral] = useState({
        CompanyId: '',
        ClientId: '',
        RunnerId: null,
        OrderType: '',
        Instructions: '',
        PayType: 0,
        ChangeOf: 0,
        TipPercent: 0,
        Coupon: '',
        orderLinesList: [],
        ClientAddress: '',
        TableNumber: 0,
        PreparationTime: 0,
        delivery: 0,
        Distance: 0,
        Subtotal: 0,
        IgnoreCostDelivery: false,
    });

    const reset = () => {
        setOrderGeneral({
            CompanyId: '',
            ClientId: '',
            RunnerId: null,
            OrderType: '',
            Instructions: '',
            PayType: 0,
            ChangeOf: 0,
            TipPercent: 0,
            Coupon: '',
            orderLinesList: [],
            ClientAddress: '',
            TableNumber: 0,
            PreparationTime: 0,
            delivery: 0,
            Distance: 0,
            Subtotal: 0,
            IgnoreCostDelivery: false,
        });
    };

    return (
        <OrderContext.Provider value={{ orderGeneral, setOrderGeneral, reset }}>
            {children}
        </OrderContext.Provider>
    );
}
