import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
    Typography,
    Layout,
    Steps,
    Descriptions,
    Divider,
    Button,
    Skeleton,
    Alert,
    Collapse,
    notification,
} from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import Footer from '../../../components/Client/Footer';
import CancelOrderForm from '../../../components/Client/CancelOrderForm';
import HeaderTop from '../../../components/General/HeaderTop';
import Modal from '../../../components/General/Modal';
import useUser from '../../../hooks/useUser';
import useAuth from '../../../hooks/useAuth';
import { getAccessTokenApi } from '../../../api/auth';
import { getCompanyApi, getCompanyIdApi } from '../../../api/company';
import { getOrderApi, getActiveOrderApi } from '../../../api/order';
import { askForNotificationPermission } from '../../../utils/subscriptions';
import { formatMoney, formatPhoneNumber } from '../../../utils/general';
import Logo from '../../../assets/img/jpg/background.jpg';

import './StatusOrder.scss';

const StatusOrder = () => {
    let history = useHistory();
    const { Step } = Steps;
    const { person } = useUser();
    const { shortName } = person;
    const { user } = useAuth();
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();
    const [companyImage, setCompanyImage] = useState('');
    const [orderClient, setOrderClient] = useState(null);
    const [infoCompany, setInfoCompany] = useState(null);
    const [status, setStatus] = useState(-1);
    const [reloadPage, setreloadPage] = useState(false);
    const [activeOrder, setActiveOrder] = useState(false);
    const [timeOutCancel, setTimeOutCancel] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const { Panel } = Collapse;
    const { Text, Paragraph } = Typography;

    function getOrder(orderId) {
        getOrderApi(token, companyId, orderId).then((res) => {
            if (res === undefined) {
                notification['error']({
                    message: 'No cuenta con internet',
                });
                return false;
            }

            if (res.status === 400) {
                setActiveOrder(false);
            } else {
                setActiveOrder(true);

                let dayMoment = moment.unix(res.orderDateTimeUnix);

                let now = moment();
                dayMoment.add(10, 'minutes');
                let dif = dayMoment.diff(now, 'minutes');

                if (dif <= 0) {
                    setTimeOutCancel(false);
                } else {
                    setTimeOutCancel(true);
                }
            }

            if (res.status === 7) {
                if (reloadPage) {
                    notification['warning']({
                        message: 'Orden Cancelada',
                        description: `${res.cancelationReason} , para más información contactese con nosotros.`,
                    });
                }
                setActiveOrder(false);

                history.push('/');
                return false;
            }
            setOrderClient(res);

            switch (res.status) {
                case 1:
                    setStatus(-1);
                    break;
                case 2:
                    setStatus(0);
                    break;
                case 3:
                    setStatus(1);
                    break;
                case 4:
                    setStatus(2);
                    break;
                case 5:
                    setStatus(3);
                    break;
                case 6:
                    setStatus(4);
                    break;

                default:
                    setStatus(-1);
                    break;
            }

            if (res.status === 6) {
                if (reloadPage) {
                    notification['success']({
                        message: 'Orden Pagada',
                        description: 'Disfrute su orden..',
                    });
                }

                history.push('/');
            }
        });
        setreloadPage(false);
    }

    useEffect(() => {
        const clientId =
            user[
            'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
            ];
        const roleId =
            user[
            'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
        askForNotificationPermission(clientId, roleId, companyId, token);
        getCompanyApi(token, companyId).then((response) => {
            const extensionImage = response.companyImage.extension;
            const imageProfile = `data:image/${extensionImage};base64, ${response.companyImage.imageData}`;
            setCompanyImage(imageProfile);
            setInfoCompany(response);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (history.location.state !== undefined) {
            getOrderApi(token, companyId, history.location.state.orderId).then(
                (res) => {
                    if (res === undefined) {
                        notification['error']({
                            message: 'No cuenta con internet',
                        });
                        return false;
                    }

                    if (res.status === 400) {
                        setActiveOrder(false);
                    } else {
                        setActiveOrder(true);

                        let dayMoment = moment.unix(res.orderDateTimeUnix);

                        let now = moment();
                        dayMoment.add(10, 'minutes');
                        let dif = dayMoment.diff(now, 'minutes');

                        if (dif <= 0) {
                            setTimeOutCancel(false);
                        } else {
                            setTimeOutCancel(true);
                        }
                    }

                    if (res.status === 7) {
                        if (reloadPage) {
                            notification['warning']({
                                message: 'Orden Cancelada',
                                description: `${res.cancelationReason} , para más información contactese con nosotros.`,
                            });
                        }
                        setActiveOrder(false);

                        history.push('/');

                        return false;
                    }
                    setOrderClient(res);

                    switch (res.status) {
                        case 1:
                            setStatus(-1);
                            break;
                        case 2:
                            setStatus(0);
                            break;
                        case 3:
                            setStatus(1);
                            break;
                        case 4:
                            setStatus(1);
                            break;
                        case 5:
                            setStatus(2);
                            break;
                        case 6:
                            setStatus(3);
                            break;

                        default:
                            setStatus(-1);
                            break;
                    }

                    if (res.status === 6) {
                        if (reloadPage) {
                            notification['success']({
                                message: 'Orden Pagada',
                                description: 'Disfrute su orden..',
                            });
                        }

                        history.push('/');
                    }
                }
            );
            setreloadPage(false);
        } else {
            const clientId =
                user[
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
                ];
            getActiveOrderApi(token, companyId, clientId).then((res) => {
                if (!res.statusCode) {
                    if (res.orderId) {
                        getOrder(res.orderId);
                        setActiveOrder(true);
                        setreloadPage(false);
                    } else {
                        setActiveOrder(false);
                        history.push('/');
                    }
                } else {
                    if (res.statusCode === 404) {
                        setActiveOrder(false);
                        setOrderClient('no Order');
                        // history.push("/");
                    }
                }
            });
        }
        window.scrollTo(0, 0);
        return () => {
            setOrderClient(null);
            setStatus(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadPage]);

    const cancelOrder = () => {
        let dayMoment = moment.unix(orderClient.orderDateTimeUnix);

        let now = moment();
        dayMoment.add(10, 'minutes');
        let dif = dayMoment.diff(now, 'minutes');

        if (dif <= 0) {
            setTimeOutCancel(false);
            notification['error']({
                message:
                    'Ya excedió el tiempo limite para poder cancelar la orden.',
            });
        } else {
            setTimeOutCancel(true);
            setContextModal(
                <CancelOrderForm
                    order={orderClient}
                    setIsModalVisible={setIsModalVisible}
                    setreloadPage={setreloadPage}
                    history={history}
                />
            );
            setIsModalVisible(true);
        }
    };

    return (
        <>
            <Layout className="status-order">
                <HeaderTop
                    logoCompany={
                        companyImage === null
                            ? false
                            : companyImage === ''
                                ? false
                                : true
                    }
                    title={`¡Hola, ${shortName}!`}
                    imageCompany={companyImage === null ? Logo : companyImage}
                />
                <Divider orientation="left">Información de tu orden</Divider>
                <div className="status-order__container">
                    {orderClient == null && (
                        <Skeleton active paragraph={{ rows: 5 }} />
                    )}

                    {orderClient !== null &&
                        infoCompany !== null &&
                        activeOrder && (
                            <>
                                <Descriptions>
                                    <Descriptions.Item label="Tiempo de preparación">
                                        {orderClient.preparationTime === 0
                                            ? 'Pendiente'
                                            : `${orderClient.preparationTime} minutos`}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Tipo de entrega">
                                        {orderClient.orderType === 1
                                            ? 'Para llevar'
                                            : orderClient.orderType === 2
                                                ? 'A Domicilio'
                                                : 'Para Comer Aqui'}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Sucursal">
                                        {`${infoCompany.address} Col. ${infoCompany.neighborhood} CP. ${infoCompany.zipCode}, ${infoCompany.city} ${infoCompany.state}`}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Teléfono de sucursal">
                                        {formatPhoneNumber(infoCompany.phone)}{' '}
                                        {infoCompany.phoneAlternate === ''
                                            ? ''
                                            : infoCompany.phoneAlternate ===
                                                null
                                                ? ''
                                                : ' / '}
                                        {formatPhoneNumber(
                                            infoCompany.phoneAlternate
                                        )}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Ubicación de entrega">
                                        {orderClient.orderType === 1
                                            ? 'Sucursal'
                                            : orderClient.orderType === 2
                                                ? orderClient.clientAddress
                                                : `Mesa ${orderClient.tableNumber}`}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="No. de Pedido">
                                        {orderClient.orderNum}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Fecha y Hora">
                                        {moment
                                            .unix(orderClient.orderDateTimeUnix)
                                            .format('DD/MM/YYYY')}{' '}
                                        {moment
                                            .unix(orderClient.orderDateTimeUnix)
                                            .format('h:mm A')}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Método de pago">
                                        {' '}
                                        {orderClient.payType === 1
                                            ? `Efectivo ${orderClient.changeOf -
                                                orderClient.total >
                                                0
                                                ? ', recibirá $' +
                                                formatMoney(
                                                    orderClient.changeOf -
                                                    orderClient.total
                                                ) +
                                                ' al pagar con $' +
                                                formatMoney(
                                                    orderClient.changeOf
                                                )
                                                : ''
                                            }`
                                            : 'Tarjeta bancaria'}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Total">
                                        {' '}
                                        ${formatMoney(orderClient.total)}
                                    </Descriptions.Item>
                                </Descriptions>

                                {orderClient.ignoreDeliveryCost &&
                                    orderClient.orderType === 2 && (
                                        <Alert
                                            message="Costo de envío pendiente..."
                                            type="warning"
                                            showIcon
                                            style={{
                                                marginTop: 10,
                                                marginBottom: 10,
                                            }}
                                        />
                                    )}
                                <Collapse
                                    ghost
                                    defaultActiveKey={['1']}
                                    expandIcon={({ isActive }) => (
                                        <CaretRightOutlined
                                            rotate={isActive ? 90 : 0}
                                        />
                                    )}
                                >
                                    {/* <Panel header="Detalle del pedido " key="1">
                                    {orderClient.details.map((item) => {
                                        return (
                                            <p key={item.itemId} className="status-order-items">
                                                [{item.quantity}] {item.itemName}{' '}
                                                {item.instructions !== ''
                                                    ? `(${item.instructions})`
                                                    : ''}
                                            </p>
                                        );
                                    })}
                                </Panel> */}

                                    <Panel header="Detalle del pedido " key="1">
                                        {orderClient.details.map((item) => {
                                            console.log(item);
                                            return (
                                                <div
                                                    key={item.itemId}
                                                    className="status-order-items"
                                                >
                                                    <span
                                                    // key={item.itemId}
                                                    // className="status-order-items"
                                                    >
                                                        [{item.quantity}]{' '}
                                                        {item.itemName}{' '}
                                                        {item.instructions !==
                                                            ''
                                                            ? `(${item.instructions})`
                                                            : ''}
                                                    </span>

                                                    {item.selectedOptions
                                                        .length > 0 &&
                                                        (<Collapse
                                                            ghost
                                                            defaultActiveKey={['2']}
                                                            expandIcon={({ isActive }) => (
                                                                <CaretRightOutlined
                                                                    rotate={isActive ? 90 : 0}
                                                                />
                                                            )}
                                                        >
                                                            <Panel header="Opciones" key="2">

                                                                {item.selectedOptions.map(
                                                                    (option) => {
                                                                        return (
                                                                            <Paragraph
                                                                                className="section-items"
                                                                                key={
                                                                                    option.name +
                                                                                    option.id
                                                                                }
                                                                            >
                                                                                <Text
                                                                                    strong
                                                                                >{`${option.sectionName}: `}</Text>
                                                                                <Text>{`${option.name}`}</Text>

                                                                                {option.price && (
                                                                                    <Text
                                                                                        type="secondary"
                                                                                        className="gray margin-left"
                                                                                    >
                                                                                        $
                                                                                        {formatMoney(
                                                                                            option.price
                                                                                        )}
                                                                                    </Text>
                                                                                )}
                                                                            </Paragraph>
                                                                        );
                                                                    }
                                                                )}
                                                            </Panel>
                                                        </Collapse>





                                                        )
                                                    }
                                                </div>
                                            );
                                        })}
                                    </Panel>
                                </Collapse>
                            </>
                        )}
                </div>

                {activeOrder && (
                    <div className="status-order__container-steps">
                        <Divider orientation="left">Estatus</Divider>

                        {orderClient !== null && orderClient.orderType === 1 ? (
                            <Steps direction="vertical" current={status}>
                                <Step
                                    title="Orden aceptada por el restaurante"
                                    description="Empezaremos a preparar tu orden"
                                />
                                {/* <Step title="Preparando tu orden" description="Estamos realizando tu orden" /> */}
                                <Step
                                    title="Tu orden ya esta lista"
                                    description="Ya puedes pasar al restaurante"
                                />
                                <Step
                                    title="Tu orden fue entregada"
                                    description="Orden completada"
                                />
                            </Steps>
                        ) : (
                            <Steps direction="vertical" current={status}>
                                <Step
                                    title="Orden aceptada por el restaurante"
                                    description="Empezaremos a preparar tu orden"
                                />
                                {/* <Step title="Preparando tu orden" description="Estamos realizando tu orden" /> */}
                                <Step
                                    title="Tu orden va en camino"
                                    description="Llegará pronto"
                                />
                                <Step
                                    title="Tu orden llegó"
                                    description="Orden lista"
                                />
                            </Steps>
                        )}
                    </div>
                )}

                {!activeOrder && orderClient !== null && (
                    <div className="status-order__container-steps">
                        <Alert
                            message="No hay Orden"
                            description="En estos momentos no cuenta con una orden activa"
                            type="info"
                            showIcon
                        />
                    </div>
                )}

                {activeOrder && (
                    <div className="status-order__container-buttons">
                        {status === -1 && timeOutCancel && (
                            <Button
                                type="primary"
                                danger
                                block
                                onClick={cancelOrder}
                            >
                                Cancelar pedido
                            </Button>
                        )}

                        <Button
                            className="btn-resfresh"
                            danger
                            block
                            onClick={() => setreloadPage(true)}
                        >
                            Actualizar Status
                        </Button>
                    </div>
                )}

                <Modal
                    isVisible={isModalVisible}
                    setIsVisible={setIsModalVisible}
                    title={'Cancelar Orden'}
                    footer={false}
                    destroyOnClose
                >
                    {contextModal}
                </Modal>
            </Layout>
            <Footer basket={false} showOrder showHome isOrder />
        </>
    );
};

export default StatusOrder;
