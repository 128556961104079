import React, {useState} from 'react';
import { Button, Divider, Alert, Skeleton,notification } from 'antd';
import { AuditOutlined } from '@ant-design/icons';
import {getAccessTokenApi} from '../../../api/auth';
import { createStripeAccountLinkApi, getCompanyIdApi } from "../../../api/company";


const PaymentAccount = ({stripeConfigured, stripeAccountId, loading}) => {
    const [loadingAccount, setLoadingAccount] = useState(false);
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();

    const createAccount = async () => {
        setLoadingAccount(true);
        const result =  await createStripeAccountLinkApi(token, companyId);
        

        if(result === undefined){
            notification["error"]({
                message: "Hubo un problema, revise su conexión a internet"
            });
            setLoadingAccount(false);
            return false;
        }

        if(result.statusCode === 200){
            let url = result.result.url;

            notification["success"]({
                message: "Registro exitoso"
            });

            setTimeout(() => {
                window.location.href = url;
            }, 500);



        }else{
            notification["error"]({
                message: "Hubo un problema, intentelo de nuevo"
            });
        }
        setLoadingAccount(false);
        
    }
    return (
        <div className="text-center">
            <Divider><h1>Pagos Stripe</h1></Divider>

            <Skeleton loading={loading ? true: false} active={true} paragraph={true} />

            {!loading && <div className="payment-account-container">

            {stripeConfigured && stripeAccountId !== null && <Alert
                message="Su cuenta esta configurada"
                description="Su cuenta esta lista para recibir pagos con tarjeta."
                type="success"
                showIcon
            />}

            {!stripeConfigured && stripeAccountId !== null && <Alert
                message="Su cuenta  NO esta configurada"
                description="Por favor termine el registro correctamente para poder recibir pagos con tarjeta."
                type="warning"
                showIcon
            />}

            {!stripeConfigured && stripeAccountId === null && <Alert
                message="No ha creado una cuenta de Stripe"
                description="Cree una cuenta para poder usar el servicio de pago en línea."
                type="error"
                showIcon
            />}
            </div>}

            {!loading && <Button
                className="margin-button-perfil"
                size="large"
                type="primary"
                icon={<AuditOutlined />}
                loading={loadingAccount}
                onClick={() => createAccount()}
                >
                    {stripeAccountId !== null ? 'Actualizar cuenta en Stripe' : '¡Crear Cuenta en Stripe!'}
                    
            </Button>}
            
        </div>
    )
}

export default PaymentAccount;
