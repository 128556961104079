import React, { useEffect, useState, useRef } from 'react';
import {
    Layout,
    Typography,
    List,
    Image,
    Button,
    Divider,
    Row,
    Col,
    notification,
    Skeleton,
} from 'antd';
import {
    RightOutlined,
    // PlusCircleTwoTone,
    CheckCircleTwoTone,
} from '@ant-design/icons';
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';
// import { apiKeyStripePublic } from '../../../api/config';
import HeaderTop from '../../../components/General/HeaderTop';
import VisaCard from '../../../assets/img/png/Visa-Emblema.png';
import MasterCard from '../../../assets/img/png/masterCard.png';
import AmericaExpress from '../../../assets/img/png/americaExpress.png';
import { getAccessTokenApi } from '../../../api/auth';
import { getStripePaymentMethodsApi } from '../../../api/company';
import './CreditCards.scss';

// const promise = loadStripe(apiKeyStripePublic, {
//     locale: 'es-419',
// });

const CreditCards = ({ history }) => {
    const isMounted = useRef(true);
    const [cards, setCards] = useState([]);
    const [cardSelect, setCardSelect] = useState('');
    const [loading, setLoading] = useState(true);

    const { Content } = Layout;

    useEffect(() => {
        stripeMethods();
        return () => {
            isMounted.current = false;
        };
    }, []);

    const stripeMethods = () => {
        const token = getAccessTokenApi();
        getStripePaymentMethodsApi(token).then((data) => {
            console.log(data);

            if (isMounted.current) {
                if (data?.statusCode === 200) {
                    setCards(data.result);
                    // setStripePaymentMethod(data.result[0]['id']);
                    setCardSelect(data.result[0]['id']);
                } else {
                    notification['error']({
                        message:
                            'Ocurrio un error al cargar la información, intentelo mas tarde.',
                    });
                }
            }

            setLoading(false);
        });
    };

    // const handleSelectCard = (id) => {
    //     setCardSelect(id);
    //     // setStripePaymentMethod(id);
    // };

    return (
        <Layout className="credit-cards">
            <Content className="credit-cards__content">
                <HeaderTop
                    backIcon={true}
                    route={'/payment'}
                    title={'Tarjetas'}
                />
                <div className="credit-cards__container">
                    {/* <Elements stripe={promise}></Elements> */}
                    <Divider>Tarjetas Guardadas</Divider>

                    <ListCard
                        cards={cards}
                        cardSelect={cardSelect}
                        loading={loading}
                        history={history}
                    />

                    <Divider></Divider>

                    {/* <AddCard /> */}
                </div>
            </Content>
        </Layout>
    );
};

function ListCard(props) {
    const { cards, cardSelect, loading, history } = props;

    const listData = [];
    for (let i = 0; i < 3; i++) {
        listData.push({
            href: 'https://ant.design',
            title: `ant design part ${i}`,
        });
    }

    const handleGoCard = (id) => {
        history.push(`/payment/cards/${id}`);
    };

    return (
        <>
            {!loading && (
                <List
                    itemLayout="horizontal"
                    dataSource={cards}
                    renderItem={(card) => (
                        <List.Item
                            onClick={() => handleGoCard(card.id)}
                            actions={[
                                <Button type="text">
                                    <RightOutlined
                                        style={{ color: '#b5b3b3' }}
                                    />
                                </Button>,
                            ]}
                        >
                            <List.Item.Meta
                                avatar={<AvatarCard card={card} />}
                                title={
                                    <TitleCard
                                        card={card}
                                        cardSelect={cardSelect}
                                    />
                                }
                            />
                        </List.Item>
                    )}
                />
            )}

            {loading && (
                <List
                    itemLayout="horizontal"
                    dataSource={listData}
                    renderItem={(item) => (
                        <List.Item>
                            <Skeleton
                                loading={loading}
                                active
                                paragraph={false}
                                title
                                avatar={{ shape: 'square' }}
                            ></Skeleton>
                        </List.Item>
                    )}
                />
            )}
        </>
    );
}

function TitleCard(props) {
    const { card, cardSelect } = props;
    const { Text } = Typography;
    return (
        <Row>
            <Col>
                <Text>{`... ${card.card.last4}`}</Text>
            </Col>
            <Col>
                <>
                    {cardSelect === card.id && (
                        <CheckCircleTwoTone
                            twoToneColor="#52c41a"
                            style={{ fontSize: '16px', marginLeft: 20 }}
                        />
                    )}
                </>
            </Col>
        </Row>
    );
}

function AvatarCard({ card }) {
    let srcImage = '';
    switch (card.card.brand) {
        case 'visa':
            srcImage = VisaCard;
            break;

        case 'masterCard':
            srcImage = MasterCard;
            break;

        case 'americaExpress':
            srcImage = AmericaExpress;
            break;

        default:
            srcImage = VisaCard;
            break;
    }
    return <Image width={25} shape="square" src={srcImage} />;
}

// function AddCard() {
//     const { Text } = Typography;

//     return (
//         <Row className="add-card">
//             <Col span={24}>
//                 <Button
//                     shape="round"
//                     block
//                     danger
//                     size="large"
//                     icon={
//                         <PlusCircleTwoTone
//                             style={{ fontSize: 18 }}
//                             twoToneColor="#ff7875"
//                         />
//                     }
//                     className="add-card__button"
//                 >
//                     Agregar método de pago
//                 </Button>
//                 <Text type="secondary"> Tarjeta de crédito/débito</Text>
//             </Col>
//         </Row>
//     );
// }

export default CreditCards;
