import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
    Button,
    Collapse,
    Descriptions,
    Select,
    Input,
    Row,
    Col,
    Divider,
    notification,
    Alert,
    Spin,
    Modal as ModalAntd,
    Tooltip,
    Typography,
} from 'antd';
import {
    CheckOutlined,
    EditOutlined,
    CloseOutlined,
    PrinterOutlined,
    ExclamationCircleOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { formatMoney, formatPhoneNumber } from '../../../utils/general';
import { getAccessTokenApi, logout } from '../../../api/auth';
import { getCompanyIdApi } from '../../../api/company';
import { editOrderStatus } from '../../../api/order';
import EditOrderForm from '../../../components/Admin/EditOrderForm';
import CancelOrderForm from '../../../components/Admin/CancelOrderForm';
import Modal from '../../../components/General/Modal';
import './DescriptionOrder.scss';

const DescriptionOrder = (props) => {
    const {
        item,
        orders,
        distanceMaxDelivery,
        distanceBaseKM,
        setReloadOrders,
        setContextModal,
        setIsModalVisible,
        setReloadPendingOrders,
        setReloadHistorialOrders,
        setTitleModal,
    } = props;
    const { Option } = Select;
    const { Panel } = Collapse;
    const { confirm } = ModalAntd;
    const [minutes, setMinutes] = useState(null);
    // const [cancelationReason, setCancelationReason] = useState("");
    const [loadingItem, setLoadingItem] = useState(false);
    const [orderActive, setOrderActive] = useState(0);
    // const [statusInput, setStatusInput] = useState(1);
    const [showError, setShowError] = useState({
        error: false,
        message: '',
    });
    const [isModalVisibleDetail, setIsModalVisibleDetail] = useState(false);
    const [contextModalDetail, setContextModalDetail] = useState(null);
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();
    let history = useHistory();

    const {
        statusText,
        status,
        orderNum,
        orderTypeText,
        orderDateTimeUnix,
        details,
        payType,
        changeOf,
        total,
        clientPhoneNumber,
        clientName,
        orderId,
        preparationTime,
        clientAddress,
        orderType,
        distance,
        ignoreDeliveryCost,
        delivery,
    } = item;

    let payTypeOrder = '';
    if (payType === 1) {
        if (changeOf !== 0) {
            if (changeOf - total !== 0) {
                payTypeOrder = `Efectivo, necesita $${formatMoney(
                    changeOf - total
                )} de cambio`;
            } else {
                payTypeOrder = 'Efectivo, no necesita cambio.';
            }
        } else {
            payTypeOrder = 'Efectivo, no necesita cambio.';
        }
    } else {
        payTypeOrder = 'Con tarjeta bancaria';
    }

    let disabledButtonConfirm = false;
    let disabledButtonEdit = false;
    let disabledButtonCancel = false;

    let disableStatusNew = false;
    let disableStatusApproved = false;
    // let disableStatusPreparation = false;
    let disableStatusDelivery = false;
    let disableStatusDelivered = false;
    let disableStatusPaid = false;

    switch (status) {
        case 1:
            disableStatusApproved = true;
            // disableStatusPreparation = true;
            disableStatusDelivery = true;
            disableStatusDelivered = true;
            disableStatusPaid = true;

            break;

        case 2:
            // disabledButtonEdit = true;
            if (payType === 2) {
                disabledButtonEdit = true;
                disabledButtonCancel = true;
            }
            disabledButtonConfirm = true;
            //status
            disableStatusPaid = true;
            disableStatusNew = true;
            // disableStatusDelivery = true;
            disableStatusDelivered = true;
            disableStatusPaid = true;

            break;

        case 3:
            disabledButtonEdit = true;
            disabledButtonConfirm = true;
            if (payType === 2) {
                disabledButtonCancel = true;
            }
            //status
            disableStatusPaid = true;
            disableStatusNew = true;
            disableStatusApproved = true;
            disableStatusDelivered = true;
            disableStatusPaid = true;
            break;

        case 4:
            disabledButtonConfirm = true;
            disabledButtonEdit = true;
            if (payType === 2) {
                disabledButtonCancel = true;
            }
            //status
            disableStatusNew = true;
            disableStatusApproved = true;
            // disableStatusPreparation = true;
            disableStatusPaid = true;

            break;

        case 5:
            disabledButtonConfirm = true;
            disabledButtonEdit = true;
            if (payType === 2) {
                disabledButtonCancel = true;
            }
            //status
            disableStatusNew = true;
            disableStatusApproved = true;
            // disableStatusPreparation = true;
            // disableStatusDelivery = true;

            break;

        case 6:
            disabledButtonConfirm = true;
            disabledButtonEdit = true;
            disabledButtonCancel = true;
            //status
            disableStatusNew = true;
            disableStatusApproved = true;
            // disableStatusPreparation = true;
            disableStatusDelivery = true;
            disableStatusDelivered = true;
            disableStatusPaid = true;

            break;

        default:
            break;
    }

    function handleChange(value) {
        // setStatusInput(value);

        switch (value) {
            // case 2:
            //     showConfirm(2);
            case 3:
                showConfirm(3);
                break;
            case 4:
                showConfirm(4);
                break;

            case 5:
                showConfirm(5);
                break;
            case 6:
                showConfirm(6);
                break;

            default:
                break;
        }
    }

    const showConfirm = (status) => {
        confirm({
            title: '¿Seguro que deseas cambiar el estatus a esta orden?',
            icon: <ExclamationCircleOutlined />,
            //   content: 'Some descriptions',
            onOk() {
                changeStatusOrder(status);
            },
            onCancel() { },
        });
    };

    // const  cancelOrderConfirm = (status) => {
    //     confirm({
    //       title: '¿Seguro que deseas cancelar esta orden?',
    //       icon: <ExclamationCircleOutlined />,
    //     //   content: <CancelForm setCancelationReason={setCancelationReason}/>,
    //       onOk() {
    //         changeStatusOrder(status);
    //       },
    //       onCancel() {
    //           console.log(cancelationReason);
    //       },
    //     });
    // }

    const changeStatusOrder = async (status) => {
        let data = {
            orderId: orderId,
            status: status,
            companyId: companyId,
            cancelationReason: '',
            fromClient: false,
        };

        // return false;
        const result = await editOrderStatus(token, data);

        if (result === undefined) {
            notification['error']({
                message: 'Ocurrió un error, Intentelo mas tarde',
            });

            return false;
        } else if (result.statusCode === 200) {
            if (status === 7) {
                if (statusText === 'Aprobada') {
                    setReloadPendingOrders(true);
                } else {
                    setReloadOrders(true);
                }
            } else {
                if (status === 6) {
                    setReloadHistorialOrders(true);
                } else {
                    setReloadPendingOrders(true);
                }
            }

            notification['success']({
                message: 'Orden modificada exitosamente',
            });
        } else if (result.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });

            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1200);
        } else {
            let errorMessage = 'Ocurrio un error al modificar la orden';

            if (result.statusCode === 404) {
                errorMessage = result.description;
            }

            notification['error']({
                message: errorMessage,
            });
        }
    };

    const confirmOrder = async (
        orderId,
        ignoreDeliveryCost,
        delivery,
        orderType
    ) => {
        setLoadingItem(true);
        setOrderActive(orderId);
        if (minutes <= 0) {
            setShowError({
                error: true,
                message: 'Debes ingresar un número entero mayor a cero.',
            });
            setOrderActive(orderId);
            setLoadingItem(false);
        } else if (minutes > 60) {
            setShowError({
                error: true,
                message:
                    'EL tiempo de preparación no puede exceder de 60 minutos.',
            });
            setOrderActive(orderId);
            setLoadingItem(false);
        } else if (ignoreDeliveryCost && delivery === 0 && orderType === 2) {
            setShowError({
                error: true,
                message: 'Debes editar el costo de envio',
            });
            setOrderActive(orderId);
            setLoadingItem(false);

            setTimeout(() => {
                setShowError({
                    error: false,
                    message: '',
                });
            }, 3000);
        } else {
            if (showError) {
                setShowError({
                    error: false,
                    message: '',
                });
            }

            let data = {
                orderId: orderId,
                status: 2,
                companyId: companyId,
                preparationTime: parseInt(minutes),
                printed: true,
                fromClient: false,
            };
            const result = await editOrderStatus(token, data);

            if (result === undefined) {
                notification['error']({
                    message: 'Ocurrió un error, inténtelo mas tarde .',
                });
                setOrderActive(orderId);
                setLoadingItem(false);

                return false;
            }

            if (result.statusCode === 401) {
                notification['error']({
                    message: 'Usuario no autorizado.',
                });

                setTimeout(() => {
                    logout();
                    window.location.reload();
                }, 1200);
                return false;
            }

            if (result.statusCode === 200) {
                setLoadingItem(false);
                printOrder(orderId);
            } else {
                let msgError = 'Error, No se pudo confirmar la orden.';
                if (result.statusCode === 400) {
                    msgError = 'El pago fue rechazado.';
                }

                notification['error']({
                    message: msgError,
                });
                setOrderActive(orderId);
                setLoadingItem(false);
            }
        }
    };

    const printOrder = (idOrder) => {
        history.push(`/admin/print?idOrder=${idOrder}`);
    };

    const editOrder = (item) => {
        setTitleModal('Editar Orden');
        setContextModal(
            <EditOrderForm
                order={item}
                setIsModalVisible={setIsModalVisible}
                orders={orders}
                setReloadPendingOrders={setReloadPendingOrders}
                setReloadOrders={setReloadOrders}
            />
        );
        setIsModalVisible(true);
    };

    const cancelOrder = (item) => {
        setTitleModal('Cancelar Orden');
        setContextModal(
            <CancelOrderForm
                order={item}
                setIsModalVisible={setIsModalVisible}
                setReloadPendingOrders={setReloadPendingOrders}
                setReloadOrders={setReloadOrders}
            />
        );
        setIsModalVisible(true);
    };

    const viewDetailsItems = (item) => {
        setContextModalDetail(
            <DescriptionSectionItem
                orderClient={item}
                setIsModalVisibleDetail={setIsModalVisibleDetail}
            />
        );
        setIsModalVisibleDetail(true);
    };

    return (
        <>
            <Spin
                spinning={orderActive === orderId ? loadingItem : false}
                tip="Cargando..."
            >
                <Descriptions
                    // title={orderTypeText === "ALaMesa" ? 'A la mesa':orderTypeText ==="ADomicilio" ? "A domicilio" : orderTypeText}
                    title={TitleOrder(
                        orderTypeText,
                        distance,
                        distanceMaxDelivery,
                        distanceBaseKM
                    )}
                    size="small"
                >
                    <Descriptions.Item label="Status">
                        {statusText === 'Entrega'
                            ? orderType === 1
                                ? 'Lista'
                                : 'En camino'
                            : statusText}
                    </Descriptions.Item>
                    <Descriptions.Item label="Número de orden">
                        {orderNum}
                    </Descriptions.Item>
                    <Descriptions.Item label="Fecha y hora de solicitud">
                        {moment.unix(orderDateTimeUnix).format('L')}{' '}
                        {moment.unix(orderDateTimeUnix).format('h:mm A')}
                    </Descriptions.Item>
                    <Descriptions.Item label="Total">
                        $ {formatMoney(total)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Descripción">
                        {details.map((item) => {
                            return (
                                <Fragment key={item.itemId}>{`${item.quantity
                                    } ${item.itemName} ${item.instructions !== ''
                                        ? `( ${item.instructions})`
                                        : ''
                                    } `}</Fragment>
                            );
                        })}
                    </Descriptions.Item>
                </Descriptions>
                <Button
                    type="ghost"
                    danger
                    onClick={() => viewDetailsItems(item)}
                    size="small"
                >
                    Ver Orden Completa
                </Button>
                <Collapse ghost className="description-order">
                    <Panel header="Más Información" key={item.title}>
                        <Descriptions
                            size="small"
                            className="description-order__container"
                        >
                            <Descriptions.Item label="Tipo de pago">
                                {payTypeOrder}
                            </Descriptions.Item>
                            <Descriptions.Item label="Teléfono del cliente">
                                {formatPhoneNumber(clientPhoneNumber)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Nombre del cliente">
                                {clientName}
                            </Descriptions.Item>
                            <Descriptions.Item label="Ubicación del cliente">
                                {orderType === 1 ? 'Sucursal' : clientAddress}
                            </Descriptions.Item>
                            {orderTypeText === 'ADomicilio' && (
                                <Descriptions.Item label="Distancia del cliente">
                                    {distance} Km
                                </Descriptions.Item>
                            )}
                            {orderTypeText === 'ADomicilio' &&
                                distance - distanceMaxDelivery > 0 && (
                                    <Descriptions.Item label="Distancia excedida">
                                        {(
                                            distance - distanceMaxDelivery
                                        ).toFixed(2)}{' '}
                                        Km
                                    </Descriptions.Item>
                                )}
                            <Descriptions.Item label="Estatus">
                                <Select
                                    defaultValue={status}
                                    style={{ width: 120 }}
                                    value={status}
                                    onChange={handleChange}
                                >
                                    <Option
                                        value={1}
                                        disabled={disableStatusNew}
                                    >
                                        Nueva
                                    </Option>
                                    <Option
                                        value={2}
                                        disabled={disableStatusApproved}
                                    >
                                        Aprobada
                                    </Option>
                                    {/* <Option value={3} disabled={disableStatusPreparation}>Preparación</Option> */}
                                    <Option
                                        value={4}
                                        disabled={disableStatusDelivery}
                                    >
                                        {orderType === 1
                                            ? 'Lista'
                                            : 'En camino'}
                                    </Option>
                                    <Option
                                        value={5}
                                        disabled={disableStatusDelivered}
                                    >
                                        Entregada
                                    </Option>
                                    <Option
                                        value={6}
                                        disabled={disableStatusPaid}
                                    >
                                        Pagada
                                    </Option>
                                </Select>
                            </Descriptions.Item>
                            <Descriptions.Item label="Tiempo de preparación">
                                <Input
                                    value={
                                        status !== 1 ? preparationTime : minutes
                                    }
                                    placeholder="0"
                                    suffix={'minutos'}
                                    onChange={(e) => setMinutes(e.target.value)}
                                    disabled={status !== 1 ? true : false}
                                />
                            </Descriptions.Item>
                        </Descriptions>
                        {showError.error && (
                            <h1 className="label-error">{showError.message}</h1>
                        )}

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Button
                                    icon={<CheckOutlined />}
                                    disabled={disabledButtonConfirm}
                                    onClick={() =>
                                        confirmOrder(
                                            orderId,
                                            ignoreDeliveryCost,
                                            delivery,
                                            orderType
                                        )
                                    }
                                >
                                    Confirmar
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    icon={<EditOutlined />}
                                    disabled={disabledButtonEdit}
                                    onClick={() => editOrder(item)}
                                >
                                    Editar
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    icon={<CloseOutlined />}
                                    disabled={disabledButtonCancel}
                                    onClick={() => cancelOrder(item)}
                                >
                                    Cancelar
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    disabled={status === 1}
                                    icon={<PrinterOutlined />}
                                    onClick={() => printOrder(orderId)}
                                >
                                    Reimprimir
                                </Button>
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>
                <Divider />
                <Modal
                    isVisible={isModalVisibleDetail}
                    setIsVisible={setIsModalVisibleDetail}
                    title={'Detalle de la orden'}
                    footer={false}
                >
                    {contextModalDetail}
                </Modal>
            </Spin>
        </>
    );
};

function TitleOrder(
    orderTypeText,
    distance,
    distanceMaxDelivery,
    distanceBaseKM,
    ignoreDeliveryCost
) {
    return (
        <Row>
            {distance > distanceMaxDelivery && orderTypeText === 'ADomicilio' && (
                <Col span={24} className="mb-10">
                    <Alert
                        message={
                            <span>
                                Envío fuera del rango de entrega.
                                <Tooltip
                                    title={
                                        ignoreDeliveryCost
                                            ? 'Marcar al cliente para confirmar ubicación y establecer costo de envio'
                                            : 'Se sugiere marcar al cliente para confirmar ubicación.'
                                    }
                                >
                                    <QuestionCircleOutlined
                                        style={{ marginLeft: 6 }}
                                    />
                                </Tooltip>
                            </span>
                        }
                        type="error"
                        showIcon
                    />
                </Col>
            )}
            {distance - distanceBaseKM > 0 && distance < distanceMaxDelivery && (
                <Col span={24} className="mb-10">
                    <Alert
                        message={
                            <span>
                                Envío fuera del rango base.
                                <Tooltip title="Se sugiere marcar al cliente para confirmar ubicación.">
                                    <QuestionCircleOutlined
                                        style={{ marginLeft: 6 }}
                                    />
                                </Tooltip>
                            </span>
                        }
                        type="warning"
                        showIcon
                    />
                </Col>
            )}
            <Col span={24}>
                {orderTypeText === 'ALaMesa'
                    ? 'A la mesa'
                    : orderTypeText === 'ADomicilio'
                        ? 'A domicilio'
                        : orderTypeText}
            </Col>
        </Row>
    );
}

function DescriptionSectionItem({ orderClient }) {
    const { Title, Text } = Typography;
    const { Panel } = Collapse;

    return (
        <div>
            {orderClient.details.map((item) => {
                console.log(item);
                return (
                    <Row
                        className="basket-form__container-items"
                        key={item.itemId}
                    >
                        <Col span={24}>
                            <Row className="align-items">
                                <Title level={5}>
                                    {`[ ${item.quantity} ] - ${item.itemName}  `}
                                </Title>
                                {item.orderDetailUom !== null && (

                                    <p className="margin-left">({item.orderDetailUom.units} {item.orderDetailUom.unitTypeName})</p>
                                )}
                            </Row>

                            <p className="gray">
                                ${formatMoney(item.linePrice)}


                            </p>
                            <div className="basket-form__container-items">
                                {item.selectedOptions.map((option) => (
                                    <Row key={option.id}>
                                        <Col span={24}>
                                            <Text className="section-items">
                                                <Text
                                                    strong
                                                >{`${option.sectionName}: `}</Text>
                                                <Text>{`${option.name}`}</Text>

                                                {option.price && (
                                                    <Text className="gray margin-left">
                                                        $
                                                        {formatMoney(
                                                            option.price
                                                        )}
                                                    </Text>
                                                )}
                                            </Text>
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                            {item.instructions !== '' && (
                                <Collapse ghost>
                                    <Panel header="Instrucciones" key="1">
                                        <p>{item.instructions}</p>
                                    </Panel>
                                </Collapse>
                            )}

                        </Col>
                    </Row>
                );
            })}
            {/* {orderClient.details.map((item) => {
                // console.log(item);
                return (
                    <div key={item.itemId} className="status-order-items">
                        <span>
                            [{item.quantity}] {item.itemName}{' '}
                            {item.instructions !== ''
                                ? `(${item.instructions})`
                                : ''}
                        </span>
                        {item.selectedOptions.length > 0 &&
                            item.selectedOptions.map((option) => {
                                return (
                                    <p
                                        style={{
                                            marginBottom: 2,
                                        }}
                                        key={option.name + option.id}
                                    >
                                        {option.name}
                                    </p>
                                );
                            })}
                    </div>
                );
            })} */}
        </div>
    );
}
export default DescriptionOrder;
