import React from 'react';
import { Layout, Typography } from 'antd';
import HeaderTop from '../../../components/General/HeaderTop';
import RecordsOrders from '../../../components/Client/RecordsOrders';

import './OrderHistory.scss';

const OrderHistory = () => {

    const { Content } = Layout;
    const {Title} = Typography

    return (
        <Layout className="order-history">
        <Content>
            <HeaderTop 
                title={"órdenes"} 
                backIcon={true} 
                backUrl={'/'}
            />

            <Title level={4} className="text-center" style={{marginTop: 20}}>Todas las órdenes</Title>

            <RecordsOrders />
        </Content>
    </Layout>
    )
}

export default OrderHistory;
