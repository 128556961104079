import React from 'react';
import { Form, Input, Button, notification } from 'antd';
import { getAccessTokenApi } from '../../../api/auth';
import { validateReportAccessCodeApi } from '../../../api/user';

const AuthAdminForm = (props) => {
    const { setIsAuth } = props;

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    const onFinish = async (values) => {
        console.log('Success:', values);
        const { code } = values;
        const token = getAccessTokenApi();
        const result = await validateReportAccessCodeApi(token, code);

        if (result?.status === 200) {
            notification['success']({
                message: 'Bienvenido.',
            });
            setIsAuth(true);
        } else {
            notification['error']({
                message: 'No tiene permiso.',
            });
        }
    };

    return (
        <Form {...layout} name="basic" onFinish={onFinish}>
            <Form.Item
                label="Código"
                name="code"
                rules={[
                    {
                        required: true,
                        message: 'Ingrese un código',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    Enviar
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AuthAdminForm;
