import React, { useEffect, useState } from 'react';
import {
    Form,
    Input,
    InputNumber,
    Button,
    Tooltip,
    Spin,
    notification,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import useUser from '../../../hooks/useUser';
import { getEmailUserApi, getAccessTokenApi } from '../../../api/auth';
import { getUserApi, updateUserApi } from '../../../api/user';

const PerfilForm = () => {
    const { person, setPerson } = useUser();
    const [perfil, setPerfil] = useState([]);
    const [disableButton, setDisableButton] = useState(true);
    const [loading, setLoading] = useState(false);
    const accessToken = getAccessTokenApi();
    const email = getEmailUserApi(accessToken);
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    useEffect(() => {
        getUserApi(email).then((response) => {
            const data = JSON.parse(response);
            setPerfil([
                {
                    name: ['Name'],
                    value: data.name,
                },
                {
                    name: ['LastName'],
                    value: data.lastName,
                },
                {
                    name: ['PhoneNumber'],
                    value: data.phoneNumber,
                },
            ]);
        });
    }, [email]);

    const onFinish = async (values) => {
        setLoading(true);
        values.PhoneNumber = values.PhoneNumber.toString();
        delete values.Email;

        const result = await updateUserApi(accessToken, values);

        if (!result) {
            notification['error']({
                message: 'Ocurrio un error, revise su conexión de internet.',
            });
        } else {
            if (result.statusCode === 200) {
                notification['success']({
                    message: 'Perfil editado exitosamente.',
                });
                const nameUser = perfil[0].value;
                const firstName = nameUser.split(' ');
                const phoneUser = perfil[2].value;

                setPerson({
                    ...person,
                    name: nameUser,
                    shortName:
                        firstName[0] === '' ? firstName[1] : firstName[0],
                    phoneNumber: phoneUser.toString(),
                });
            } else {
                let errorMsg = 'Ocurrio un error.';
                notification['error']({
                    message: errorMsg,
                });
            }
        }
        setLoading(false);
    };

    return (
        <Spin spinning={loading} tip="Guardando...">
            <Form
                {...layout}
                name="perfilClient"
                initialValues={{
                    Name: person.name,
                    LastName: person.lastName,
                    Email: email,
                    PhoneNumber: person.phoneNumber,
                }}
                fields={perfil}
                onFieldsChange={(changedFields, allFields) => {
                    setPerfil(allFields);
                    if (disableButton !== false) {
                        setDisableButton(false);
                    }
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Nombre"
                    name="Name"
                    rules={[
                        {
                            required: true,
                            message: 'Debe introducir un nombre.',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Apellido"
                    name="LastName"
                    rules={[
                        {
                            required: true,
                            message: 'Debe introducir un apellido.',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item name="Email" label="E-mail">
                    <Input disabled />
                </Form.Item>

                <Form.Item
                    name="PhoneNumber"
                    label={
                        <span>
                            Teléfono&nbsp;
                            <Tooltip title="Tú número telefónico debe contener 10 dígitos">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingresa tú número telefónico',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || value.toString().length === 10) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(
                                    'Por favor ingresa tú número telefónico a 10 dígitos'
                                );
                            },
                        }),
                    ]}
                >
                    <InputNumber
                        style={{
                            width: '100%',
                        }}
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        disabled={disableButton}
                    >
                        Guardar
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default PerfilForm;
