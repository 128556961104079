import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {
    Typography,
    InputNumber,
    Popconfirm,
    Collapse,
    Skeleton,
    message,
    Select,
    Button,
    Radio,
    Image,
    Alert,
    Empty,
    Spin,
    Row,
    Col,
    Divider,
    notification,
} from 'antd';
import {
    DollarOutlined,
    CreditCardOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import { formatMoney } from '../../../utils/general';
import { getAccessTokenApi, logout } from '../../../api/auth';
import { getCompanyImageApi } from '../../../api/company';
import { createOrderApi } from '../../../api/order';
import useAuth from '../../../hooks/useAuth';
import useOrder from '../../../hooks/useOrder';
import Modal from '../../../components/General/Modal';
import DeliveryCostForm from '../../../components/Client/DeliveryCostForm';
import { apiKeyStripePublic } from '../../../api/config';
import useCompany from '../../../hooks/useCompany';
import CheckoutForm from '../../../components/Client/CheckoutForm';

import './BasketForm.scss';

const promise = loadStripe(apiKeyStripePublic, {
    locale: 'es-419',
});

const BasketForm = (props) => {
    const { setCounterItem, setIsModalVisible, infoCompany } = props;

    const { orderGeneral, setOrderGeneral, reset } = useOrder();

    const { Title, Paragraph, Text } = Typography;
    const [paypalType, setPaypalType] = useState(
        orderGeneral.PayType === 0 ? 1 : orderGeneral.PayType
    );
    const [orderState, setOrderState] = useState(orderGeneral);
    const [reloadOrder, setReloadOrder] = useState(false);
    const [activeSpin, setActiveSpin] = useState(false);
    const [messageActiveSpin, setMessageActiveSpin] = useState(
        'Enviando la orden...'
    );
    const [change, setChange] = useState(0);
    const { company } = useCompany();
    const { user } = useAuth();
    const token = getAccessTokenApi();
    const companyImage = getCompanyImageApi();
    const [isModalVisibleCost, setIsModalVisibleCost] = useState(false);
    let history = useHistory();
    const [confirmOrder, setConfirmOrder] = useState(true);
    const [stripePaymentMethod, setStripePaymentMethod] = useState('');
    //new address
    const [isValidAddress, setisValidAddress] = useState(
        orderGeneral.OrderType !== 2
            ? true
            : orderGeneral.ClientAddress !== ''
            ? true
            : false
    );
    const [orderDelivery, setOrderDelivery] = useState(orderGeneral.delivery);
    const [orderDeliveryKM, setOrderDeliveryKM] = useState(
        orderGeneral.Distance
    );
    const [orderType, setOrderType] = useState(orderGeneral.OrderType);
    const [ignoredCostDelivery, setIgnoredCostDelivery] = useState(
        orderGeneral.IgnoreCostDelivery
    );
    const [isModalVisibleAddress, setIsModalVisibleAddress] = useState(false);
    const [acceptCardCredit, setAcceptCardCredit] = useState(true);

    let typeOrder;
    let deliveyPlace = '';

    useEffect(() => {
        setOrderType(orderGeneral.OrderType);
        setisValidAddress(
            orderGeneral.OrderType !== 2
                ? true
                : orderGeneral.ClientAddress !== ''
                ? true
                : false
        );
        setOrderDelivery(orderGeneral.delivery);
        setOrderDeliveryKM(orderGeneral.Distance);
        setIgnoredCostDelivery(orderGeneral.IgnoreCostDelivery);
    }, [orderGeneral]);

    switch (orderGeneral.OrderType) {
        case 1:
            typeOrder = 'Para Llevar';
            deliveyPlace = 'Sucursal';
            break;

        case 2:
            typeOrder = 'A Domicilio';
            deliveyPlace = orderGeneral.ClientAddress;
            break;

        case 3:
            typeOrder = 'Para Comer Aqui';
            deliveyPlace = `Mesa: ${orderGeneral.TableNumber}`;

            break;

        default:
            break;
    }

    let [subtotal, setSubtotal] = useState(0);
    let [total, setTotal] = useState(0);
    const [tip, setTip] = useState(orderGeneral.TipPercent);
    const [typeOrderText, setTypeOrderText] = useState(typeOrder);

    useEffect(() => {
        let suma = 0;
        console.log(orderState, 'ORDERSTATE');

        orderState.orderLinesList.forEach((element) => {
            if (element.typeUoms !== undefined) {
                suma =
                    suma +
                    (element.extraPrice + element.priceUoms) * element.quantity;
            } else {
                suma =
                    suma +
                    (element.extraPrice + element.price) * element.quantity;
            }
        });

        setSubtotal(suma);
        setTotal(suma);
        setReloadOrder(false);
        setCounterItem(orderGeneral.orderLinesList.length);

        setOrderGeneral({
            ...orderGeneral,
            Subtotal: suma,
            orderLinesList: orderState.orderLinesList,
        });

        return () => {
            setSubtotal(0);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadOrder]);

    const [address, setAddress] = useState(deliveyPlace);
    const { Option } = Select;
    const { Panel } = Collapse;

    const onChangePayment = (e) => {
        setPaypalType(e.target.value);
        setOrderGeneral({ ...orderGeneral, PayType: e.target.value });
        if (e.target.value === 2) {
            setConfirmOrder(false);
            setMessageActiveSpin('Cargando...');
        } else {
            setConfirmOrder(true);
        }
    };

    function onChangePaypal(value) {
        setChange(value);
    }

    function confirm(itemId) {
        const found = orderState.orderLinesList.findIndex(
            (item) => item.itemId === itemId
        );
        orderState.orderLinesList.splice(found, 1);
        setOrderState(orderState);
        setReloadOrder(true);
        message.success('Eliminado exitosamente');
    }

    function cancel(e) {}

    function incrementItem(dish) {
        const found = orderState.orderLinesList.findIndex(
            (item) => item.itemId === dish.itemId
        );
        orderState.orderLinesList[found].quantity = dish.quantity + 1;
        setOrderState(orderState);
        setReloadOrder(true);
    }

    function decrementItem(dish) {
        const found = orderState.orderLinesList.findIndex(
            (item) => item.itemId === dish.itemId
        );
        orderState.orderLinesList[found].quantity = dish.quantity - 1;
        setOrderState(orderState);
        setReloadOrder(true);
    }

    function handleChangeTip(value) {
        setOrderGeneral({ ...orderGeneral, TipPercent: value });
        setTip(value);
    }

    const createOrder = async () => {
        setMessageActiveSpin('Enviando la orden...');
        setActiveSpin(true);

        if (isValidAddress === false) {
            //mostar modal de direccion
            setIsModalVisibleAddress(true);
            setActiveSpin(false);
            return false;
        }

        orderState.ClientId =
            user[
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
            ];

        orderState.ChangeOf = change;
        orderState.PayType = paypalType;
        orderState.TipPercent = tip;
        orderState.ClientAddress = address;
        orderState.OrderType = orderType;
        orderState.delivery = orderDelivery;
        orderState.Distance = orderDeliveryKM;

        if (orderType === 2) {
            orderState['IgnoreDeliveryCost'] = ignoredCostDelivery;
        } else {
            orderState['IgnoreDeliveryCost'] = false;
        }

        let totalOrder = 0;
        if (orderType === 2) {
            if (ignoredCostDelivery) {
                totalOrder = total + (subtotal * tip) / 100;
            } else {
                totalOrder = total + (subtotal * tip) / 100 + orderDelivery;
            }
        } else {
            totalOrder = total + (subtotal * tip) / 100;
        }

        if (orderGeneral.ChangeOf === null) {
            orderGeneral.ChangeOf = 0;
        }

        if (orderState.PayType === 1) {
            if (orderState.ChangeOf !== 0) {
                if (orderState.ChangeOf <= totalOrder) {
                    setActiveSpin(false);

                    notification['error']({
                        message:
                            'El cambio debe ser IGUAL o MAYOR que el total',
                    });

                    return false;
                }
            }
        }

        //payment intent id
        orderState.StripePaymentMethod = stripePaymentMethod;

        let orderSend = orderState;

        if (orderSend.orderLinesList && orderSend.orderLinesList.length > 0) {
            orderSend.orderLinesList.forEach((orderLine) => {
                orderLine.selectedOptionIds = [];
                if (orderLine.options) {
                    orderLine.options.forEach((opt) => {
                        orderLine.selectedOptionIds = [
                            ...orderLine.selectedOptionIds,
                            opt.sectionOptionId,
                        ];
                    });
                }
                orderLine.itemId = parseInt(orderLine.itemId);
            });
        }

        // console.log('Orden a enviar', orderState);
        const result = await createOrderApi(token, orderSend);

        if (result !== undefined) {
            if (result.statusCode === 200) {
                notification['success']({
                    message: 'Orden creada exitosamente',
                });
                setActiveSpin(false);
                reset();
                history.push({
                    pathname: '/statusOrder',
                    state: {
                        orderId: result.result.orderId,
                        // orderActive: result.result,
                    },
                });
            } else {
                let message = 'Ocurio un error, intentelo de nuevo';

                if (result.statusCode === 404) {
                    message = result.description;

                    setActiveSpin(false);
                    notification['error']({
                        message: message,
                    });
                } else if (result.statusCode === 405) {
                    setActiveSpin(false);
                    console.log(result);
                    notification['error']({
                        message: result.description,
                    });
                    history.push('/disable/restaurant');
                } else if (result.statusCode === 401) {
                    setActiveSpin(false);
                    console.log(result);
                    notification['error']({
                        message: result.description,
                    });
                    logout();
                    setTimeout(() => {
                        window.location.href = '/login';
                    }, 1000);
                } else {
                    setActiveSpin(false);
                    notification['error']({
                        message: message,
                    });
                }
            }
        } else {
            let message = 'Ocurio un error, intentelo de mas tarde';
            setActiveSpin(false);
            setIsModalVisible(false);
            notification['error']({
                message: message,
            });
        }
    };

    const showModalDeliveryCost = () => {
        setIsModalVisibleCost(true);
    };

    return (
        <div className="basket-form">
            <Spin spinning={activeSpin} tip={messageActiveSpin} size="large">
                <Row>
                    <Col span={6} className="basket-form__container-image">
                        {company == null && companyImage === null && (
                            <Skeleton.Avatar
                                active={true}
                                size="large"
                                shape="square"
                            />
                        )}
                        <Image width={60} src={companyImage} />
                    </Col>
                    <Col span={16} className="basket-form__container-right">
                        {company == null && infoCompany == null && (
                            <Skeleton active paragraph={{ rows: 2 }} />
                        )}

                        <Title level={3}>
                            {company !== null ? company.Name : ''}
                        </Title>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Paragraph style={{ textAlign: 'center' }}>
                            {company !== null
                                ? `${company.Location}, Col. ${company.Neighborhood} ${company.ZipCode}, ${company.City} ${company.State}.`
                                : ''}
                        </Paragraph>
                    </Col>
                </Row>

                <Divider>Detalles</Divider>

                {/* <Row className="basket-form__container">
                <Col span={14} className="basket-form__container-left">
                    <Paragraph>Tiempo de entrega</Paragraph>
                </Col>
                <Col span={10} className="basket-form__container-right">
                    <Paragraph>15-25 min</Paragraph>
                </Col>
            </Row> */}

                <Row className="basket-form__container">
                    <Col span={14} className="basket-form__container-left">
                        <Paragraph>Tipo de entrega</Paragraph>
                    </Col>
                    <Col span={10} className="basket-form__container-right">
                        <Paragraph>{typeOrderText}</Paragraph>
                    </Col>
                </Row>

                {/* <Row className="basket-form__container">
                <Col span={14} className="basket-form__container-left">
                    <Paragraph>Sucursal</Paragraph>
                </Col>
                <Col span={10} className="basket-form__container-right">
                    <Paragraph>{company !== null ? company.address : infoCompany.Location}</Paragraph>
                </Col>
            </Row> */}

                <Row className="basket-form__container">
                    <Col
                        span={orderType === 2 ? 24 : 14}
                        className="basket-form__container-left"
                    >
                        <Paragraph>Ubicación de Entrega</Paragraph>
                    </Col>
                    <Col
                        span={orderType === 2 ? 24 : 10}
                        className={[
                            'basket-form__container-right',
                            orderType === 2 && 'text-center',
                        ]}
                    >
                        {address !== '' && <Paragraph>{address}</Paragraph>}
                        {address === '' && (
                            <Alert
                                message="¡Dirección de entrega PENDIENTE!"
                                banner
                            />
                        )}
                    </Col>
                </Row>

                {orderType === 2 && isValidAddress && (
                    <>
                        <Divider>Costo de Envío</Divider>

                        <div className="basket-form__container">
                            {/* <Row className="basket-form__container-cost-delivery">
                                <Col span={24}>
                                    <Title level={5}>Importante</Title>
                                    <Paragraph>Costo minimo de envío</Paragraph>
                                    <Paragraph>
                                        ${formatMoney(infoCompany.DeliveryCost)} (0-
                                        {infoCompany.DeliveryCostKM}km)
                                    </Paragraph>
                                    <Paragraph>
                                        Km extra ${formatMoney(infoCompany.DeliveryExtraKmCost)}
                                    </Paragraph>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col span={12}>
                                    <Button
                                        type="primary"
                                        onClick={() => showModalDeliveryCost()}
                                    >
                                        Detalle de envío
                                    </Button>
                                </Col>
                                <Col span={12} className="text-left">
                                    <Alert
                                        showIcon
                                        message="Aprobado"
                                        type="success"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </>
                )}

                <Divider>Tu Orden</Divider>

                {orderState.orderLinesList.length > 0 && (
                    <div className="basket-form__container-add">
                        <Button
                            size="small"
                            type="primary"
                            onClick={() => setIsModalVisible(false)}
                        >
                            Agregar Platillos
                        </Button>
                    </div>
                )}

                <div
                    className={
                        orderState.orderLinesList.length > 3
                            ? 'basket-form__container-groupsItem'
                            : ''
                    }
                >
                    {orderState.orderLinesList.map((item) => {
                        return (
                            <Row
                                className="basket-form__container-items"
                                key={item.itemId}
                            >
                                <Col span={14}>
                                    <Title level={4}>{item.name}</Title>
                                    <Row>
                                        <p className="gray">
                                            $
                                            {formatMoney(
                                                item.priceUoms
                                                    ? item.priceUoms
                                                    : item.price
                                            )}
                                        </p>
                                        {item.measurementUnits !== 0 && (
                                            <p className="gray">
                                                -({item.measurementUnits})
                                                {item.typeUoms}
                                            </p>
                                        )}
                                    </Row>

                                    {item.instructions !== '' && (
                                        <Collapse ghost>
                                            <Panel
                                                header="Instrucciones"
                                                key="1"
                                            >
                                                <p>{item.instructions}</p>
                                            </Panel>
                                        </Collapse>
                                    )}
                                    <div className="basket-form__container-items">
                                        {item.options.map((option) => (
                                            <Row key={option.sectionOptionId}>
                                                <Col span={24}>
                                                    <Text className="section-items">
                                                        <Text
                                                            strong
                                                        >{`${option.nameSection}: `}</Text>
                                                        <Text>{`${option.name}`}</Text>

                                                        {option.price && (
                                                            <Text className="gray margin-left">
                                                                $
                                                                {formatMoney(
                                                                    option.price
                                                                )}
                                                            </Text>
                                                        )}
                                                    </Text>
                                                </Col>
                                            </Row>
                                        ))}
                                    </div>
                                </Col>
                                <Col span={10}>
                                    <Row className="counter">
                                        <Col span={8}>
                                            <Button
                                                disabled={item.quantity === 1}
                                                onClick={() =>
                                                    decrementItem(item)
                                                }
                                                danger
                                            >
                                                -
                                            </Button>
                                        </Col>
                                        <Col span={8}>{item.quantity}</Col>
                                        <Col span={8}>
                                            <Button
                                                onClick={() =>
                                                    incrementItem(item)
                                                }
                                                danger
                                            >
                                                +
                                            </Button>
                                        </Col>
                                        <Col span={24} className="right">
                                            <Popconfirm
                                                title={`¿Estás seguro de eliminar el platillo: ${item.name}?`}
                                                onConfirm={() =>
                                                    confirm(item.itemId)
                                                }
                                                onCancel={cancel}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <Button
                                                    icon={<DeleteOutlined />}
                                                />
                                            </Popconfirm>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        );
                    })}
                </div>

                {orderState.orderLinesList.length === 0 && (
                    <Empty
                        className="empty-basket"
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{
                            height: 60,
                        }}
                        description={<span>No hay ningun platillo</span>}
                    >
                        <Button
                            size="small"
                            type="primary"
                            onClick={() => setIsModalVisible(false)}
                        >
                            Agregar
                        </Button>
                    </Empty>
                )}

                <Row className="basket-form__container">
                    <Col span={14} className="basket-form__container-left">
                        <Paragraph>Total de la orden</Paragraph>
                    </Col>
                    <Col span={10} className="basket-form__container-right">
                        <Paragraph>${formatMoney(subtotal)}</Paragraph>
                    </Col>
                </Row>
                <Row className="basket-form__container">
                    <Col span={14} className="basket-form__container-left">
                        <Paragraph>Envío</Paragraph>
                    </Col>
                    <Col span={10} className="basket-form__container-right">
                        {typeOrderText === 'A Domicilio' &&
                            orderDelivery === 0 && (
                                <Skeleton
                                    className="cost-delivey"
                                    title
                                    active
                                    paragraph={false}
                                ></Skeleton>
                            )}
                        {typeOrderText === 'A Domicilio' &&
                            orderDelivery !== 0 &&
                            !ignoredCostDelivery && (
                                <Paragraph>
                                    ${formatMoney(orderDelivery)}
                                </Paragraph>
                            )}
                        {typeOrderText === 'A Domicilio' &&
                            orderDelivery !== 0 &&
                            ignoredCostDelivery && (
                                <Paragraph strong mark>
                                    Pendiente
                                </Paragraph>
                            )}
                        {typeOrderText !== 'A Domicilio' && (
                            <Paragraph>${formatMoney(0)}</Paragraph>
                        )}
                    </Col>
                </Row>
                <Row className="basket-form__container">
                    <Col span={12} className="basket-form__container-left">
                        <Paragraph>Propina</Paragraph>
                    </Col>
                    <Col span={12} className="basket-form__container-right">
                        <Select
                            value={tip}
                            defaultValue={10}
                            style={{ width: 140 }}
                            onChange={handleChangeTip}
                        >
                            <Option value={0}>0% (${0.0})</Option>
                            <Option value={5}>
                                5% (${formatMoney((subtotal * 5) / 100)})
                            </Option>
                            <Option value={10}>
                                10% (${formatMoney((subtotal * 10) / 100)})
                            </Option>
                            <Option value={15}>
                                15% (${formatMoney((subtotal * 15) / 100)})
                            </Option>
                            <Option value={20}>
                                20% (${formatMoney((subtotal * 20) / 100)})
                            </Option>
                            <Option value={25}>
                                25% (${formatMoney((subtotal * 25) / 100)})
                            </Option>
                        </Select>
                    </Col>
                </Row>
                <Divider></Divider>
                <Row className="basket-form__container">
                    <Col span={14} className="basket-form__container-left">
                        <Title level={5}>Total</Title>
                    </Col>
                    <Col span={10} className="basket-form__container-right">
                        <Title level={4}>
                            $
                            {/* {formatMoney(
                                typeOrderText === 'A Domicilio'
                                    ? total + (subtotal * tip) / 100 + orderDelivery
                                    : total + (subtotal * tip) / 100
                            )} */}
                            {formatMoney(
                                typeOrderText === 'A Domicilio'
                                    ? ignoredCostDelivery
                                        ? total + (subtotal * tip) / 100
                                        : total +
                                          (subtotal * tip) / 100 +
                                          orderDelivery
                                    : total + (subtotal * tip) / 100
                            )}
                        </Title>
                    </Col>
                </Row>

                <Divider>Método de Pago</Divider>

                <div className="basket-form__container-paypal">
                    <Radio.Group
                        defaultValue={orderGeneral.PayType}
                        size="large"
                        onChange={onChangePayment}
                        value={paypalType}
                    >
                        <Radio.Button
                            value={1}
                            disabled={infoCompany.CashOnly ? false : true}
                        >
                            {' '}
                            <DollarOutlined /> Efectivo
                        </Radio.Button>
                        <Radio.Button
                            value={2}
                            disabled={
                                infoCompany.CreditCard && acceptCardCredit
                                    ? false
                                    : true
                            }
                        >
                            {' '}
                            <CreditCardOutlined /> Tarjeta T/C
                        </Radio.Button>
                    </Radio.Group>

                    {paypalType === 2 && infoCompany.CreditCard && (
                        <Row className="change">
                            <Elements stripe={promise}>
                                <CheckoutForm
                                    setConfirmOrder={setConfirmOrder}
                                    setStripePaymentMethod={
                                        setStripePaymentMethod
                                    }
                                    setActiveSpin={setActiveSpin}
                                    setAcceptCardCredit={setAcceptCardCredit}
                                />
                            </Elements>
                            {/* <Col span={24} className="change-left">
                            <Title level={5} type="danger">¡Aviso!</Title>
                            <Paragraph>El repartidor acudirá con la terminal para el pago.</Paragraph>
                        </Col> */}
                        </Row>
                    )}

                    {paypalType === 1 && infoCompany.CashOnly && (
                        <Row className="change">
                            <Col span={6} className="change-left">
                                <Paragraph>Cambio de</Paragraph>
                            </Col>
                            <Col span={18} className="change-right">
                                <InputNumber
                                    min={0}
                                    value={change}
                                    defaultValue={0}
                                    formatter={(value) =>
                                        `$ ${value}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ','
                                        )
                                    }
                                    parser={(value) =>
                                        value.replace(/\$\s?|(,*)/g, '')
                                    }
                                    onChange={onChangePaypal}
                                    style={{
                                        width: '50%',
                                    }}
                                />
                            </Col>
                        </Row>
                    )}
                </div>

                <Button
                    disabled={
                        orderState.orderLinesList.length === 0 ||
                        confirmOrder === false
                    }
                    type="primary"
                    danger
                    block
                    onClick={createOrder}
                >
                    Confirmar Pedido
                </Button>

                {orderType === 2 && (
                    <Modal
                        isVisible={isModalVisibleCost}
                        setIsVisible={setIsModalVisibleCost}
                        title={'Costo de Envío'}
                        footer={false}
                    >
                        <DeliveryCostForm
                            setIsModalVisibleCost={setIsModalVisibleCost}
                            address={address}
                            infoCompany={infoCompany}
                            history={null}
                        />
                    </Modal>
                )}

                <Modal
                    isVisible={isModalVisibleAddress}
                    setIsVisible={setIsModalVisibleAddress}
                    title={'Direción'}
                    footer={false}
                >
                    <DeliveryCostForm
                        setIsModalVisibleCost={setIsModalVisibleCost}
                        address={''}
                        infoCompany={infoCompany}
                        history={'client-first'}
                        action="add-first"
                        setAddressClient={setAddress}
                        setOrderDelivery={setOrderDelivery}
                        setisValidAddress={setisValidAddress}
                        setIsModalVisibleAddress={setIsModalVisibleAddress}
                        setTypeOrderText={setTypeOrderText}
                        setOrderType={setOrderType}
                        setOrderDeliveryKM={setOrderDeliveryKM}
                    />
                </Modal>
            </Spin>
        </div>
    );
};

export default BasketForm;
