import { basePath } from "./config";
import { notification } from "antd";

export function getAllItemSections(token, companyId, menuItemId) {
    const url = `${basePath}/ItemSections/GetAll?companyId=${companyId}&menuItemId=${menuItemId}`;

    const params = {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "*/*",
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
            notification["error"]({
                message: "No cuenta conexión a internet",
            });
        });
}
//CREAR SECCION
export function createItemSection(token, data) {
    const url = `${basePath}/ItemSections`;

    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "*/*",
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
            notification["error"]({
                message: "No cuenta conexión a internet",
            });
        });
}
//EDITAR SECCION
export function editItemSection(token, data, itemId) {
    const url = `${basePath}/ItemSections?id=${itemId}`;

    const params = {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "*/*",
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}
//ELIMINAR SECCION
export function deleteItemSection(token, idSection) {
    const url = `${basePath}/ItemSections?id=${idSection}`;

    const params = {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "*/*",
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response;
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}
//CREAR OPCION
export function createSectionOption(token, data) {
    const url = `${basePath}/SectionOptions`;

    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "*/*",
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
            notification["error"]({
                message: "No cuenta conexión a internet",
            });
        });
}
//ELIMINAR OPCION
export function deleteSectionOptions(token, idOption) {
    const url = `${basePath}/SectionOptions?id=${idOption}`;

    const params = {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "*/*",
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response;
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}
//EDITAR OPCION
export function editSectionOptions(token, data, idOption) {
    const url = `${basePath}/SectionOptions?id=${idOption}`;

    const params = {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "*/*",
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}
