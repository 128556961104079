import React, { useState, useEffect } from 'react';
import { Button, Divider, Layout, notification } from 'antd';
import moment from 'moment';
import MenuTop from '../../../components/Admin/MenuTop';
import PerfilForm from '../../../components/Admin/PerfilForm';
import ScheduleForm from '../../../components/Admin/ScheduleForm';
import PaymentAccount from '../../../components/Admin/PaymentAccount';
import CodigoQr from '../../../components/Admin/CodigoQr';
import { getAccessTokenApi, logout } from '../../../api/auth';
import {
    getCompanyApi,
    getCompanyIdApi,
    getCompanySchedule,
    getCompanyNameApi,
} from '../../../api/company';
import {
    askForNotificationPermission,
    getMobileOperatingSystem,
} from '../../../utils/subscriptions';
import useAuth from '../../../hooks/useAuth';
import { COMPANY_NAME } from '../../../utils/constants';

import './Perfil.scss';

export default function Perfil() {
    let [perfilForm, setPerfilForm] = useState(null);

    let [scheduleForm, setScheduleForm] = useState([
        {
            name: ['name'],
            value: '',
        },
    ]);

    let [loading, setloading] = useState(true);

    let [loadingSchedule, setloadingSchedule] = useState(true);

    let [reloadSchedule, setReloadSchedule] = useState(false);

    let [imagePerfil, setImagePerfil] = useState('');

    let [imageQr, setImageQr] = useState('');

    let [urlPage, setUrlPage] = useState('');

    let [schedule, setSchedule] = useState(null);

    const [showDelivery, setShowDelivery] = useState(false);

    const [stripeAccountId, setStripeAccountId] = useState('');

    const [stripeConfigured, setStripeConfigured] = useState(false);

    const [stateAddress, setStateAddress] = useState('');

    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();
    const companyName = getCompanyNameApi();
    const { user } = useAuth();

    const [roleUser, setRoleUser] = useState('Operator');

    let [showSchedule, setShowSchedule] = useState({
        showMonday: false,
        showTuesday: false,
        showWednesday: false,
        showThursday: false,
        showFriday: false,
        showSaturday: false,
        showSunday: false,
    });

    useEffect(() => {
        const roleId =
            user[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
        setRoleUser(roleId);
    }, [user]);

    useEffect(() => {
        const clientId =
            user[
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
            ];
        const roleId =
            user[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
        askForNotificationPermission(clientId, roleId, companyId, token);
        getCompanyApi(token, companyId).then((response) => {
            console.log(response);
            if (response === undefined) {
                return false;
            }

            if (response.statusCode === 401) {
                notification['error']({
                    message: 'Usuario no autorizado',
                });

                setTimeout(() => {
                    logout();
                    window.location.reload();
                }, 1500);
                return;
            }

            if (!response.error) {
                if (companyName !== response.name) {
                    localStorage.setItem(COMPANY_NAME, response.name);
                }

                setShowDelivery(response.delivery);

                setStripeConfigured(response.stripeConfigured);
                setStripeAccountId(response.stripeAccountId);

                if (
                    !response.address ||
                    !response.city ||
                    !response.neighborhood ||
                    !response.state ||
                    !response.zipCode
                ) {
                    setStateAddress('add');
                } else {
                    setStateAddress('edit');
                }

                setPerfilForm([
                    {
                        name: ['Name'],
                        value: response.name,
                    },
                    {
                        name: ['URL'],
                        value: response.url,
                    },
                    {
                        name: ['Email'],
                        value: response.email,
                    },
                    {
                        name: ['CompanyCategoryIds'],
                        value: response.companyCategoryIds
                            ? response.companyCategoryIds
                            : undefined,
                    },
                    {
                        name: ['Phone'],
                        value: response.phone,
                    },
                    {
                        name: ['PhoneAlternate'],
                        value: response.phoneAlternate,
                    },
                    {
                        name: ['RFC'],
                        value: response.rfc,
                    },
                    {
                        name: ['FiscalName'],
                        value:
                            response.fiscalName === 'Sin datos'
                                ? ''
                                : response.fiscalName,
                    },
                    {
                        name: ['Address'],
                        value: response.address,
                    },
                    {
                        name: ['Neighborhood'],
                        value: response.neighborhood,
                    },
                    {
                        name: ['ZipCode'],
                        value: response.zipCode,
                    },
                    {
                        name: ['City'],
                        value: response.city,
                    },
                    {
                        name: ['State'],
                        value: response.state,
                    },
                    {
                        name: ['Delivery'],
                        value: response.delivery,
                    },
                    {
                        name: ['ToTable'],
                        value: response.toTable,
                    },
                    {
                        name: ['PickUp'],
                        value: response.pickUp,
                    },
                    {
                        name: ['CashOnly'],
                        value: response.cashOnly,
                    },
                    {
                        name: ['CreditCard'],
                        value: response.creditCard,
                    },
                    {
                        name: ['DeliverryMaxDistance'],
                        value: response.deliverryMaxDistance,
                    },
                    {
                        name: ['DeliveryCost'],
                        value: response.deliveryCost,
                    },
                    {
                        name: ['DeliveryCostKM'],
                        value: response.deliveryCostKM,
                    },
                    {
                        name: ['DeliveryExtraKmCost'],
                        value: response.deliveryExtraKmCost,
                    },
                    {
                        name: ['ServiceMaxDistance'],
                        value: response.serviceMaxDistance,
                    },
                    {
                        name: ['qrUrl'],
                        value:
                            response.qrCodes[0]['url'] !== undefined
                                ? response.qrCodes[0]['url']
                                : '',
                    },
                ]);

                setUrlPage(
                    response.qrCodes[0]['url'] !== undefined
                        ? response.qrCodes[0]['url']
                        : ''
                );
                setloading(false);

                if (
                    response.companyImage !== undefined &&
                    response.companyImage !== null
                ) {
                    const extensionImage = response.companyImage.extension;
                    const imageProfile = `data:image/${extensionImage};base64, ${response.companyImage.imageData}`;
                    setImagePerfil(imageProfile);
                }

                if (response.qrCodes[0]) {
                    const imageQrUrl = `data:image/png;base64, ${response.qrCodes[0]['qrData']}`;
                    setImageQr(imageQrUrl);
                }
            }
        });

        return () => {
            setPerfilForm([]);
        };
    }, [token, companyId, user, companyName]);

    useEffect(() => {
        getCompanySchedule(token, companyId).then((res) => {
            console.log(res);
            if (res === undefined) {
                return false;
            }

            if (res?.statusCode === 401) {
                return;
            }

            setSchedule(res);
            if (res.length > 0) {
                let schedule = res;

                let showTime = {
                    showMonday: false,
                    showTuesday: false,
                    showWednesday: false,
                    showThursday: false,
                    showFriday: false,
                    showSaturday: false,
                    showSunday: false,
                };

                let now = moment().format('YYYY-MM-DD');

                if (schedule.length >= 0) {
                    schedule.forEach((ele) => {
                        let day = '';
                        let dayStart = '';
                        let dayEnd = '';

                        switch (ele.dayOfWeek) {
                            case 1:
                                day = 'monday';
                                dayStart = 'mondayStart';
                                dayEnd = 'mondayEnd';
                                showTime.showMonday = true;
                                break;

                            case 2:
                                day = 'tuesday';
                                dayStart = 'tuesdayStart';
                                dayEnd = 'tuesdayEnd';
                                showTime.showTuesday = true;

                                break;

                            case 3:
                                day = 'wednesday';
                                dayStart = 'wednesdayStart';
                                dayEnd = 'wednesdayEnd';
                                showTime.showWednesday = true;
                                break;

                            case 4:
                                day = 'thursday';
                                dayStart = 'thursdayStart';
                                dayEnd = 'thursdayEnd';
                                showTime.showThursday = true;
                                break;

                            case 5:
                                day = 'friday';
                                dayStart = 'fridayStart';
                                dayEnd = 'fridayEnd';
                                showTime.showFriday = true;
                                break;

                            case 6:
                                day = 'saturday';
                                dayStart = 'saturdayStart';
                                dayEnd = 'saturdayEnd';
                                showTime.showSaturday = true;
                                break;

                            case 7:
                                day = 'sunday';
                                dayStart = 'sundayStart';
                                dayEnd = 'sundayEnd';
                                showTime.showSunday = true;

                                break;
                            default:
                                break;
                        }

                        setScheduleForm([
                            ...scheduleForm,
                            {
                                name: [day],
                                value: true,
                            },
                            {
                                name: [dayStart],
                                value: moment(`${now} ${ele.timeStart}`),
                            },
                            {
                                name: [dayEnd],
                                value: moment(`${now} ${ele.timeEnd}`),
                            },
                        ]);
                    });

                    setShowSchedule(showTime);
                }
            }
            setloadingSchedule(false);
        });
        return () => {
            setScheduleForm([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId, reloadSchedule]);

    function activeNotification() {
        const clientId =
            user[
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
            ];
        const roleId =
            user[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
        askForNotificationPermission(clientId, roleId, companyId, token);
    }

    return (
        <Layout className="perfil">
            <MenuTop title={'Perfil'} />

            <div className="perfil__content">
                {roleUser === 'Admin' && (
                    <>
                        <h1>Información general</h1>
                        <PerfilForm
                            perfilForm={perfilForm}
                            setPerfilForm={setPerfilForm}
                            loading={loading}
                            imagePerfil={imagePerfil}
                            imageQr={imageQr}
                            urlPage={urlPage}
                            showDelivery={showDelivery}
                            stateAddress={stateAddress}
                        />
                        <PaymentAccount
                            loading={loading}
                            stripeConfigured={stripeConfigured}
                            stripeAccountId={stripeAccountId}
                        />
                    </>
                )}

                <ScheduleForm
                    scheduleForm={scheduleForm}
                    setScheduleForm={setScheduleForm}
                    loadingSchedule={loadingSchedule}
                    schedule={schedule}
                    setReloadSchedule={setReloadSchedule}
                    showSchedule={showSchedule}
                />

                <CodigoQr
                    imageQr={imageQr}
                    loading={loading}
                    urlPage={urlPage}
                />

                {getMobileOperatingSystem() !== 'iOS' && (
                    <>
                        <Divider>Notificaciones</Divider>
                        <Button
                            type="primary"
                            block
                            onClick={() => activeNotification()}
                        >
                            Activar
                        </Button>
                    </>
                )}
            </div>
        </Layout>
    );
}
