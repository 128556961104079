export const citiesMTY = [
    'Apodaca',
    'Cadereyta Jiménez',
    'El Carmen',
    'García',
    'San Pedro Garza García',
    'General Escobedo',
    'Guadalupe',
    'Juárez',
    'Monterrey',
    'Salinas Victoria',
    'San Nicolás de los Garza',
    'Santa Catarina',
    'Santiago',
];
