import React, {useState} from 'react';
import { Form, Input, Button, Spin, notification } from 'antd';
import {resetPasswordApi} from '../../../api/user';
import { validatePassword } from '../../../utils/formValidation';

import './ResetPasswordForm.scss';

const ResetPasswordForm = (props) => {
    const { user } = props;
    const [loading, setLoading  ] = useState(false);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 0, span: 24 },
    };

    const onFinish = async values => {
        setLoading(true);
        let data = values;
        data['email'] = user.email;
        data['token'] = user.token;

        const result = await resetPasswordApi(data);

        if(result === undefined){
            setLoading(false);

            notification["error"]({
                message: "Ocurrió un error, Intentelo mas tarde"
            });

            setTimeout(() => {
                window.location.href = '/';
            }, 3000);
            
            return false;
        }

        if(result.statusCode === 200){
            setLoading(false);

            notification["success"]({
                message: "Operación exitosa",
            });

            setTimeout(() => {
                window.location.href = '/';
            }, 3000);

        } else{
            let messageError = "Ocurrió un error, Intentelo otra vez";
            
            if(result.statusCode === 409){
                messageError = 'El token ha aspirado.'
            }

            if(result.statusCode === 400){
                messageError = result.message;
            }

            setLoading(false);
            
            notification["error"]({
                message: messageError
            });
        }

    };
    

    return (
        <Spin tip="Cargando..." spinning={loading}>
            <Form
            {...layout}
            onFinish={onFinish}
            className='reset-password-form'
            >
                <Form.Item
                name="password"
                label="Contraseña"
                rules={[
                    {
                        required: true,
                        message: 'Por favor ingrese una contraseña',
                    },( ) =>({
                        validator(rule,value){
                            if(validatePassword(value)){
                                return Promise.resolve();
                            }else if(!validatePassword(value)){
                                return Promise.reject('La contrase\u00F1a nueva debe de tener m\u00E1s de 6 caracteres, por lo menos una may\u00FAscula y un car\u00E1cter especial');   
                            }
                            
                        }
                    })
                ]}
                hasFeedback
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                name="confirmPassword"
                label="Confirmar Contraseña"
                dependencies={['password']}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'Por favor ingrese la contraseña anterior',
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }

                        return Promise.reject('Las dos contraseñas ingresadas no son iguales');
                        },
                    }),
                ]}
            >
                <Input.Password />
            </Form.Item>

            

                <Form.Item {...tailLayout} className="reset-password-form__button">
                    <Button type="primary" htmlType="submit">
                        Enviar
                    </Button>
                </Form.Item>
            </Form>

        </Spin>
        
    )
}

export default ResetPasswordForm;
