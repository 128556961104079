import { basePath } from './config';

export function getCompleteMenu(token, idCompany) {
    const url = `${basePath}/Menu/GetCompleteMenu`;

    if (!idCompany) {
        let promResuelta = Promise.resolve({
            error: true,
        });
        return promResuelta;
    }

    const data = {
        CompanyId: idCompany,
        MenuClient: false,
    };

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function getMenuGroupApi(token, data) {
    const url = `${basePath}/Menu/getMenuGroup`;

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function getCompleteMenuClient(token, data) {
    const url = `${basePath}/Menu/GetCompleteMenu`;

    // if(!idCompany){

    //     let promResuelta = Promise.resolve({
    //         error: true
    //     });
    //     return promResuelta
    // }

    // const data = {
    //     CompanyId: idCompany
    // }

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function addGroupMenu(token, data) {
    const url = `${basePath}/Menu/AddGroup`;

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function editGroupMenu(token, data) {
    const url = `${basePath}/Menu/EditGroup`;

    const params = {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function addImageGroupMenu(token, data) {
    const url = `${basePath}/Menu/AddImageToMenuGroup`;

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function deleteGroupMenu(token, data) {
    const url = `${basePath}/Menu/DeleteGroup`;

    const params = {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function getGroupMenu(token, idCompany) {
    const url = `${basePath}/Menu/GetMenuGroups`;

    if (!idCompany) {
        let promResuelta = Promise.resolve({
            error: true,
        });
        return promResuelta;
    }

    const data = {
        CompanyId: idCompany,
    };

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

//------ CATEGORY ------//

export function addCategoryMenu(token, data) {
    const url = `${basePath}/Menu/AddCategory`;

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function editCategoryMenu(token, data) {
    const url = `${basePath}/Menu/EditCategory`;

    const params = {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function deleteCategoryMenu(token, data) {
    const url = `${basePath}/Menu/DeleteCategory`;

    const params = {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

//------ ITEMS ------//

export function addDishMenu(token, data) {
    const url = `${basePath}/Menu/AddItem`;

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function addImageDishMenu(token, data) {
    const url = `${basePath}/Menu/AddImageToMenuItem`;

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function editDishMenu(token, data) {
    const url = `${basePath}/Menu/EditItem`;

    const params = {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function deleteItemMenu(token, data) {
    const url = `${basePath}/Menu/DeleteItem`;

    const params = {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function getMenuItemsApi(token, idCompany, searchWord) {
    const url = `${basePath}/Menu/GetMenuItems`;

    const data = {
        CompanyId: idCompany,
        SearchWord: searchWord,
        OrderType: 1,
        MenuClient: true,
    };

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            console.log(err);
        });
}
