import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
    Layout,
    // Row,
    // Col,
    // Button,
    // Divider,
    // Skeleton,
    notification,
} from 'antd';
import {
    CreditCardFilled,
    // EditOutlined,
    // DeleteOutlined,
} from '@ant-design/icons';
import HeaderTop from '../../../components/General/HeaderTop';
// import Modal from '../../../components/General/Modal';
import { getAccessTokenApi } from '../../../api/auth';
import { getStripePaymentMethodsApi } from '../../../api/company';

import './PaymentCard.scss';

export default function PaymentCard() {
    const isMounted = useRef(true);
    const { cardId } = useParams();
    const [card, setCard] = useState(null);
    const [loading, setLoading] = useState(true);
    const { Content } = Layout;

    console.log(cardId);

    useEffect(() => {
        stripeMethods();
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const stripeMethods = () => {
        const token = getAccessTokenApi();
        getStripePaymentMethodsApi(token).then((data) => {
            console.log(data);

            if (isMounted.current) {
                if (data?.statusCode === 200) {
                    const allCards = data.result;

                    const cardSelect = allCards.filter(
                        (ele) => ele.id === cardId
                    );

                    console.log(cardSelect);
                    setCard(cardSelect[0]);
                    console.log(cardSelect[0]);
                } else {
                    notification['error']({
                        message:
                            'Ocurrio un error al cargar la información, intentelo mas tarde.',
                    });
                }
            }

            setLoading(false);
        });
    };
    return (
        <Layout className="payment-card">
            <Content className="payment-card__content">
                <HeaderTop
                    backIcon={true}
                    route={'/payment/cards'}
                    title={'Tarjeta'}
                />
                <div className="payment-card__container">
                    {!loading && (
                        <div className="card">
                            <div className="card__front card__part">
                                <CreditCardFilled className="card__icon " />
                                <h1 className="card__front-logo card__logo">
                                    Tarjeta Crédito / Débito
                                </h1>
                                <p className="card_numer">
                                    **** **** **** {card.card.last4}
                                </p>

                                <div className="card__space-75">
                                    <span className="card__label">Títular</span>
                                    <p className="card__info">
                                        {card.billingDetails['name']}
                                    </p>
                                </div>
                                <div className="card__space-25">
                                    <span className="card__label">
                                        Expiración
                                    </span>
                                    <p className="card__info">
                                        {card.card.expMonth < 10
                                            ? `0${card.card.expMonth}`
                                            : card.card.expMonth}
                                        /
                                        {card.card.expYear
                                            .toString()
                                            .substr(2, 3)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* <Divider>Acciónes</Divider>

                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Button
                                shape="round"
                                block
                                danger
                                size="large"
                                icon={
                                    <EditOutlined
                                        style={{
                                            fontSize: 18,
                                            color: '#ff7875',
                                        }}
                                    />
                                }
                                className="add-card__button"
                            >
                                Editar Tarjeta
                            </Button>
                        </Col>

                        <Col span={24}>
                            <Button
                                type="primary"
                                shape="round"
                                block
                                danger
                                size="large"
                                icon={
                                    <DeleteOutlined
                                        style={{
                                            fontSize: 18,
                                            color: '#fff',
                                        }}
                                    />
                                }
                                className="add-card__button"
                            >
                                Eliminar Tarjeta
                            </Button>
                        </Col>
                    </Row> */}
                </div>
            </Content>
        </Layout>
    );
}
