import React, { useEffect, useState, useRef } from 'react';
import {
    List,
    Typography,
    Collapse,
    Row,
    Col,
    Divider,
    Skeleton,
    notification,
    Button,
    Spin,
    Modal as ModalAntd,

} from 'antd';
import { ShoppingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getCompanyApi, getCompanyIdApi } from '../../../api/company';
import { getOrderReportApi, createComment, getComments } from '../../../api/order';
import { getAccessTokenApi } from '../../../api/auth';
import { formatMoney, formatPhoneNumber } from '../../../utils/general';
import useAuth from '../../../hooks/useAuth';
import TextArea from 'antd/lib/input/TextArea';
import Modal from '../../General/Modal';

import './RecordsOrders.scss';

const listData = [];
for (let i = 0; i < 23; i++) {
    listData.push({
        href: 'https://ant.design',
        title: `ant design part ${i}`,
        avatar:
            'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
        description:
            'Ant Design, a design language for background applications, is refined by Ant UED Team.',
        content:
            'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
    });
}

const RecordsOrders = () => {
    const isMounted = useRef(true);
    const { Panel } = Collapse;
    const { user } = useAuth();
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();
    const [infoCompany, setInfoCompany] = useState(null);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const [titleModal, setTitleModal] = useState('Titulo');


    const clientId =
        user[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
        ];

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        const data = {
            CompanyId: companyId,
            StatusList: [5, 6, 7],
            ClientId: clientId,
        };
        getOrderReportApi(token, data).then((res) => {
            console.log(res);
            if (isMounted.current) {
                if (res === undefined) {
                    notification['error']({
                        message: 'No cuenta conexión a internet',
                    });

                    return false;
                }
                setOrders(res);

                getCompanyApi(token, companyId).then((response) => {
                    if (response.error) {
                        return false;
                    }
                    setInfoCompany({
                        phoneCompany: response.phone,
                        phoneAlternateCompany: response.phoneAlternate,
                    });

                    setLoading(false);
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showModal = (title, order, token) => {
        setIsModalVisibleForm(true);
        setTitleModal(title);

        setContextModal(
            <AddComment
                title={title}
                order={order}
                token={token}
                setIsModalVisibleForm={setIsModalVisibleForm}
            ></AddComment>
        );
    };
    console.log(orders, "ORDERS");
    return (
        <>
            {!loading && (
                <List
                    itemLayout="vertical"
                    size="large"
                    pagination={{
                        onChange: (page) => {
                            console.log(page);
                        },
                        pageSize: 4,
                    }}
                    dataSource={orders}
                    renderItem={(item) => (
                        <List.Item key={item.orderId}>
                            <Collapse
                                accordion
                                key={item.orderId}
                                expandIconPosition="right"
                            >
                                <Panel
                                    header={<HeaderOrder order={item} showModal={showModal} token={token} />}
                                    key="1"
                                >
                                    <OrderDetail
                                        token={token}
                                        order={item}
                                        phone={infoCompany.phoneCompany}
                                        phoneAlternate={
                                            infoCompany.phoneAlternateCompany
                                                ? infoCompany.phoneAlternateCompany
                                                : false
                                        }
                                    />
                                </Panel>
                            </Collapse>
                        </List.Item>
                    )}
                />
            )}

            {loading === true && (
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={listData}
                    renderItem={(item) => (
                        <List.Item key={item.title}>
                            <Skeleton loading={loading} active avatar>
                                {item.content}
                            </Skeleton>
                        </List.Item>
                    )}
                />
            )}
            <Modal
                isVisible={isModalVisibleForm}
                setIsVisible={setIsModalVisibleForm}
                title={titleModal}
                footer={false}
            >
                {contextModal}
            </Modal>
        </>
    );
};

export default RecordsOrders;

function HeaderOrder({ order, showModal, token }) {
    const { Text, Paragraph } = Typography;
    const { statusText, orderNum, orderDateTimeUnix, total } = order;
    const titleModal = 'Agregar comentario';
    console.log(order, "ORDER PUNTO");
    return (
        <Row>
            <Col span={14}>
                <Text>
                    <ShoppingOutlined
                        style={{
                            fontSize: '20px',
                            color: '#08c',
                            marginRight: '15px',
                        }}
                    />
                    {statusText === 'Pagada' ? 'Completada' : statusText}
                </Text>
                <Paragraph style={{ marginLeft: '35px' }}>
                    #{orderNum}
                </Paragraph>
            </Col>
            <Col span={10} style={{ textAlign: 'right' }}>
                <Text>
                    {moment.unix(orderDateTimeUnix).format('DD/MM/YYYY')}
                </Text>
                <Paragraph> ${formatMoney(total)}</Paragraph>
                {/* {order.orderComment == null && (
                    <Button className="style-button"
                        htmlType="submit"
                        type="primary"
                        onClick={() =>
                            showModal(
                                titleModal,
                                order,
                                token,
                            )
                        }
                    >
                        Agregar Comentario
                    </Button>
                )} */}

            </Col>
        </Row>
    );
}

function OrderDetail({ token, phone, phoneAlternate, order }) {
    const { Text, Paragraph, Title } = Typography;
    const {
        orderType,
        payType,
        tip,
        subTotal,
        total,
        clientAddress,
        details,
        delivery,
        orderDateTimeUnix,
    } = order;


    let ordertypeText = '';
    switch (orderType) {
        case 1:
            ordertypeText = 'Recoger';
            break;
        case 2:
            ordertypeText = 'A domicilio';
            break;
        case 3:
            ordertypeText = 'A la mesa';
            break;

        default:
            break;
    }




    return (
        <>
            <Text style={{ fontWeight: 'bold' }}>Hora de solicitud</Text>
            <Paragraph>
                {moment.unix(orderDateTimeUnix).format('h:mm A')}
            </Paragraph>

            <Text style={{ fontWeight: 'bold' }}>Tipo de orden</Text>
            <Paragraph>{ordertypeText}</Paragraph>

            <Text style={{ fontWeight: 'bold' }}>Método de pago</Text>
            <Paragraph>{payType === 1 ? 'Efectivo' : 'Tarjeta T/C'}</Paragraph>

            <Divider>Productos</Divider>

            {details.map((item) => {
                return (
                    <div key={item.itemId}>
                        <Row>
                            <Col span={12}>
                                <Paragraph
                                    style={{
                                        fontWeight: '500',
                                        marginBottom: '5px',
                                    }}
                                >
                                    {item.itemName}
                                </Paragraph>
                            </Col>
                            <Col span={12} className="text-right">
                                <Text style={{ fontWeight: '500' }}>
                                    $ {formatMoney(item.unitPrice)}
                                </Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={12}>
                                <Text>Cantidad: {item.quantity}</Text>
                            </Col>
                            <Col span={12} className="text-right">
                                <Text>
                                    Total: $
                                    {formatMoney(
                                    item.unitPrice * item.quantity
                                )}
                                </Text>
                            </Col>
                        </Row>
                        <Divider
                            style={{ marginTop: 10, marginBottom: 10 }}
                        ></Divider>
                    </div>
                );
            })}

            <Text style={{ fontWeight: 'bold' }}>Dirección de envío</Text>
            <Paragraph>{clientAddress}</Paragraph>

            <Divider></Divider>

            <Row>
                <Col span={12}>
                    <Text>Subtotal:</Text>
                </Col>
                <Col span={12} className="text-right">
                    <Text>${formatMoney(subTotal)}</Text>
                </Col>
            </Row>

            <Row>
                <Col span={12}>
                    <Text>Propina: </Text>
                </Col>
                <Col span={12} className="text-right">
                    <Text>${formatMoney(tip)}</Text>
                </Col>
            </Row>

            <Row>
                <Col span={12}>
                    <Text>Envío: </Text>
                </Col>
                <Col span={12} className="text-right">
                    <Text>${formatMoney(delivery)}</Text>
                </Col>
            </Row>

            <Row>
                <Col span={12}>
                    <Title level={5}>TOTAL </Title>
                </Col>
                <Col span={12} className="text-right">
                    <Title level={5}>${formatMoney(total)}</Title>
                </Col>
            </Row>

            <Divider>Información de la Sucursal</Divider>

            <Row>
                <Col span={24}>
                    <Paragraph className="text-center">
                        Para cualquier duda o aclaración, favor de comunicarse
                        con nosotros.
                    </Paragraph>
                </Col>
                <Col span={12}>
                    <Text style={{ fontWeight: '700' }}>Teléfono:</Text>
                </Col>
                <Col span={12} className="text-right">
                    <Text style={{ fontWeight: '700' }}>
                        {formatPhoneNumber(phone)}
                    </Text>
                    {phoneAlternate && (
                        <Paragraph style={{ fontWeight: '700' }}>
                            {formatPhoneNumber(phoneAlternate)}
                        </Paragraph>
                    )}
                </Col>
            </Row>
            {/* {order.orderComment !== null && (
                <>
                    <p style={{ fontWeight: '700' }}>Comentario:</p>
                    <p>{order.orderComment.comment}</p>

                </>
            )} */}




        </>
    );
}
function AddComment(props) {
    const { title, order, token, setIsModalVisibleForm } = props;

    const [comment, setComment] = useState(null);
    const [loadingForm, setLoadingForm] = useState(false);

    const [commentSection, setCommentSection] = useState(null);
    const { confirm } = ModalAntd;
    let idOrder = order.orderId;

    // useEffect(() => {
    //     getComments(token, idOrder).then((response) => {
    //         console.log(response, "RESPONSE");
    //         if (response.statusCode) {
    //             if (response.statusCode !== 200) {
    //                 setCommentSection(null);
    //                 commentBool = true;
    //             }
    //         } else {
    //             setCommentSection(response);
    //         }
    //     });
    // }, [setCommentSection, token, idOrder]);
    // console.log(commentSection, "GETcommentSection");

    const onChange = ({ target: { value } }) => {
        setComment(value);
    };
    const addComment = () => {
        const data = {
            comment: comment,
        };
        console.log(token, data, idOrder);
        confirm({
            title: `Estas seguro que deseas agregar comentario`,
            icon: <ExclamationCircleOutlined />,
            content: 'No podras modificarlo despues...',
            onOk() {
                setLoadingForm(true);

                createComment(token, data, idOrder)
                    .then((res) => {
                        console.log(res, "REEEEES");
                        if (res.statusCode === 200) {
                            notification['success']({
                                message: `Comentario enviado exitosamente`,
                            });
                            setIsModalVisibleForm(false);
                            setLoadingForm(false);

                        }
                        if (res.statusCode === 400) {
                            notification['error']({
                                message: `Ocurrio un porblema, el comentario no se pudo agregar`,
                            });

                            return false;
                        }
                        if (res.statusCode === 404) {
                            notification['error']({
                                message: `Ocurrio un porblema, el comentario no se pudo agregar`,
                            });

                            return false;
                        }
                    })
                    .catch((err) => {
                        return false;
                    });
            },
            onCancel() { },
        });
    }

    return (
        <Spin tip="Guardando..." spinning={loadingForm} size="large">

            <>
                <TextArea
                    onChange={onChange}
                    value={comment} ></TextArea>

                <Button
                    htmlType="submit"
                    type="primary"
                    onClick={addComment}
                >
                    Agregar Comentario
                </Button>

            </>
        </Spin>
    );
}
