import React from 'react';
import { Layout, Typography, List, Button, Divider } from 'antd';
import HeaderTop from '../../components/General/HeaderTop';
import InstallPWA from '../../components/General/InstallPWA';
import useUser from '../../hooks/useUser';
import useAuth from '../../hooks/useAuth';
import {
    askForNotificationPermission,
    getMobileOperatingSystem,
    notificationActive,
} from '../../utils/subscriptions';
import { getAccessTokenApi } from '../../api/auth';
import { getCompanyIdApi } from '../../api/company';
import './Update.scss';

const Update = () => {
    const { Content } = Layout;
    const { Title } = Typography;
    const { person } = useUser();
    const { name, userType } = person;
    const { user } = useAuth();
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();

    const dataClient = [
        'Generales:',
        '-Mejoramos el performance en toda la PWA.',
        '-Se agregó funcionalidad para confirmar una dirección válida.',
        '-Agregamos la funcionalidad de recuperación de contraseña.',
        '-Se agregó pantalla para control de cambios de actualizaciones.',
        'Cliente:',
        '-Se realizaron cambios en el diseño de ventanas, ahora es más amigable y visual.',
        '-Ahora puedes navegar sobre el menú aún teniendo una orden activa.',
        '-Ya puedes visualizar el detalle de tu pedido y el total en la pantalla de seguimiento de orden.',
        '-Se calcula un costo de envío dependiendo de los km de tu dirección con el establecimiento.',
        '-Se agregó función para agregar propina.',
    ];
    const dataAdmin = [
        'Generales:',
        '-Mejoramos el performance en toda la PWA.',
        '-Se agregó funcionalidad para confirmar una dirección válida.',
        '-Agregamos la funcionalidad de recuperación de contraseña.',
        '-Se agregó pantalla para control de cambios de actualizaciones.',
        'Administrador:',
        '-Se calcula un costo de envío dependiendo de los km al editar una orden a tipo domicilio.',
        '-Se manejo un mismo diseño para la carga de menú en celular y pc.',
        '-Se agregó propina a los registros.',
        '-Ahora puedes establecer km para cobro mínimo y cantidad de cobro extra por km.',
        'Cliente:',
        '-Se realizaron cambios en el diseño de ventanas, ahora es más amigable y visual.',
        '-Ahora puedes navegar sobre el menú aún teniendo una orden activa.',
        '-Ya puedes visualizar el detalle de tu pedido y el total en la pantalla de seguimiento de orden.',
        '-Se calcula un costo de envío dependiendo de los km de tu dirección con el establecimiento.',
        '-Se agregó función para agregar propina.',
    ];

    // const updateApp = () =>{
    //     window.location.reload();
    // }
    function activeNotification() {
        const clientId =
            user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
        const roleId = user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        askForNotificationPermission(clientId, roleId, companyId, token);
        notificationActive();
    }

    return (
        <Layout className="update">
            <Content className="register__content">
                <HeaderTop
                    title={'Actualizaciones'}
                    backIcon={true}
                    backUrl={userType === 1 ? '/admin' : '/'}
                />
                <div className="update__container-title">
                    <Title level={3}>{`¡Hola, ${name}!`}</Title>
                </div>
                <div className="update__container">
                    <List
                        size="small"
                        header={<div>Actualizaciones nuevas:</div>}
                        footer={<div> Version 1.0.1 - 28/ diciembre/ 2010</div>}
                        dataSource={userType === 1 ? dataAdmin : dataClient}
                        locale={{ emptyText: 'No hay nuevas actualizaciones.' }}
                        renderItem={(item) => <List.Item>{item}</List.Item>}
                    />
                    {/* <Button type="primary" block onClick={updateApp}>Actualizar</Button> */}

                    {getMobileOperatingSystem() !== 'iOS' && (
                        <>
                            <Divider>Notificaciones</Divider>
                            <Button type="primary" block onClick={() => activeNotification()}>
                                Activar
                            </Button>
                        </>
                    )}

                    <InstallPWA />
                </div>
            </Content>
        </Layout>
    );
};

export default Update;
