import React, { useEffect, useState } from 'react';
import {
    Form,
    Row,
    Col,
    TimePicker,
    Checkbox,
    Divider,
    Button,
    Skeleton,
    notification,
    Spin,
} from 'antd';
import { getAccessTokenApi, logout } from '../../../api/auth';
import {
    getCompanyIdApi,
    addEditScheduleListToCompany,
    deleteCompanySchedule,
} from '../../../api/company';

import './Schedule.scss';

export default function ScheduleForm(props) {
    const {
        scheduleForm,
        setScheduleForm,
        loadingSchedule,
        schedule,
        setReloadSchedule,
        showSchedule,
    } = props;

    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();
    const format = 'h:mm a';
    const [disableButton, setDisableButton] = useState(true);
    const [showTime, setShowTime] = useState(showSchedule);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        setShowTime(showSchedule);
    }, [showSchedule]);

    const onFinish = async (values) => {
        setSaving(true);
        let scheduleList = [];
        let scheduleDeleteList = [];

        const formatApi = 'HH:mm';

        if (values.monday === true) {
            const found = schedule.find((element) => element.dayOfWeek === 1);
            let idSchedule = 0;
            if (found !== undefined && found !== null) {
                idSchedule = found.scheduleId;
            }

            let mondayJson = {
                CompanyId: companyId,
                DayOfWeek: values['monday'] ? 1 : null,
                StartTime: values['mondayStart'].format(formatApi),
                EndTime: values['mondayEnd'].format(formatApi),
                ScheduleId: idSchedule,
            };

            scheduleList.push(mondayJson);
        } else if (values.monday === false) {
            //delete
            const found = schedule.find((element) => element.dayOfWeek === 1);
            if (found !== undefined && found !== null) {
                scheduleDeleteList.push(found.scheduleId);
            }
        }

        if (values.tuesday === true) {
            const found = schedule.find((element) => element.dayOfWeek === 2);
            let idSchedule = 0;
            if (found !== undefined && found !== null) {
                idSchedule = found.scheduleId;
            }
            let tuesdayJson = {
                CompanyId: companyId,
                DayOfWeek: values['tuesday'] ? 2 : null,
                ScheduleId: idSchedule,
                StartTime: values['tuesdayStart'].format(formatApi),
                EndTime: values['tuesdayEnd'].format(formatApi),
            };
            scheduleList.push(tuesdayJson);
        } else if (values.tuesday === false) {
            //delete
            const found = schedule.find((element) => element.dayOfWeek === 2);
            if (found !== undefined && found !== null) {
                scheduleDeleteList.push(found.scheduleId);
            }
        }

        if (values.wednesday === true) {
            const found = schedule.find((element) => element.dayOfWeek === 3);
            let idSchedule = 0;
            if (found !== undefined && found !== null) {
                idSchedule = found.scheduleId;
            }
            let wednesdayJson = {
                CompanyId: companyId,
                DayOfWeek: values['wednesday'] ? 3 : null,
                ScheduleId: idSchedule,
                StartTime: values['wednesdayStart'].format(formatApi),
                EndTime: values['wednesdayEnd'].format(formatApi),
            };
            scheduleList.push(wednesdayJson);
        } else {
            const found = schedule.find((element) => element.dayOfWeek === 3);
            if (found !== undefined && found !== null) {
                scheduleDeleteList.push(found.scheduleId);
            }
        }

        if (values.thursday === true) {
            const found = schedule.find((element) => element.dayOfWeek === 4);
            let idSchedule = 0;
            if (found !== undefined && found !== null) {
                idSchedule = found.scheduleId;
            }
            let thursdayJson = {
                CompanyId: companyId,
                DayOfWeek: values['thursday'] ? 4 : null,
                ScheduleId: idSchedule,
                StartTime: values['thursdayStart'].format(formatApi),
                EndTime: values['thursdayEnd'].format(formatApi),
            };
            scheduleList.push(thursdayJson);
        } else {
            const found = schedule.find((element) => element.dayOfWeek === 4);
            if (found !== undefined && found !== null) {
                scheduleDeleteList.push(found.scheduleId);
            }
        }

        if (values.friday === true) {
            const found = schedule.find((element) => element.dayOfWeek === 5);
            let idSchedule = 0;
            if (found !== undefined && found !== null) {
                idSchedule = found.scheduleId;
            }
            let fridayJson = {
                CompanyId: companyId,
                DayOfWeek: values['friday'] ? 5 : null,
                ScheduleId: idSchedule,
                StartTime: values['fridayStart'].format(formatApi),
                EndTime: values['fridayEnd'].format(formatApi),
            };
            scheduleList.push(fridayJson);
        } else {
            const found = schedule.find((element) => element.dayOfWeek === 5);
            if (found !== undefined && found !== null) {
                scheduleDeleteList.push(found.scheduleId);
            }
        }

        if (values.saturday === true) {
            const found = schedule.find((element) => element.dayOfWeek === 6);
            let idSchedule = 0;
            if (found !== undefined && found !== null) {
                idSchedule = found.scheduleId;
            }
            let saturdayJson = {
                CompanyId: companyId,
                DayOfWeek: values['saturday'] ? 6 : null,
                ScheduleId: idSchedule,
                StartTime: values['saturdayStart'].format(formatApi),
                EndTime: values['saturdayEnd'].format(formatApi),
            };
            scheduleList.push(saturdayJson);
        } else {
            const found = schedule.find((element) => element.dayOfWeek === 6);
            if (found !== undefined && found !== null) {
                scheduleDeleteList.push(found.scheduleId);
            }
        }

        if (values.sunday === true) {
            const found = schedule.find((element) => element.dayOfWeek === 7);
            let idSchedule = 0;
            if (found !== undefined && found !== null) {
                idSchedule = found.scheduleId;
            }
            let sundayJson = {
                CompanyId: companyId,
                DayOfWeek: values['sunday'] ? 7 : null,
                ScheduleId: idSchedule,
                StartTime: values['sundayStart'].format(formatApi),
                EndTime: values['sundayEnd'].format(formatApi),
            };
            scheduleList.push(sundayJson);
        } else {
            const found = schedule.find((element) => element.dayOfWeek === 7);
            if (found !== undefined && found !== null) {
                scheduleDeleteList.push(found.scheduleId);
            }
        }

        const data = {
            CompanyId: companyId,
            scheduleList: scheduleList,
        };

        const dataDelete = {
            CompanyId: companyId,
            ScheduleIdList: scheduleDeleteList,
        };

        const result = await addEditScheduleListToCompany(token, data);

        if (dataDelete.ScheduleIdList.length !== 0) {
            const resultDelete = await deleteCompanySchedule(token, dataDelete);

            if (resultDelete === undefined) {
                dataDelete.ScheduleIdList = [];
            }
        }

        if (result === undefined) {
            notification['error']({
                message: 'No cuenta conexión a internet',
            });

            return false;
        }

        if (result.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado',
            });

            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1500);
            return;
        }

        if (result.statusCode === 200) {
            notification['success']({
                message: 'Horario modificado exitosamente',
            });

            setDisableButton(true);
            setReloadSchedule(true);
            setSaving(false);
        } else {
            notification['error']({
                message: 'Error al dar de alta el horario',
            });

            setDisableButton(false);
        }
    };

    const changeCheck = (e, day) => {
        setShowTime({
            ...showTime,
            [day]: e.target.checked,
        });
    };

    return (
        <div className="schedule-form">
            <Divider>
                <h1>Horario</h1>
            </Divider>

            <Spin tip="Guardando..." spinning={saving}>
                <Form
                    className="schedule-form"
                    onFinish={onFinish}
                    requiredMark={false}
                    layout="inline"
                    size="large"
                    fields={scheduleForm}
                    onFieldsChange={(changedFields, allFields) => {
                        setScheduleForm(allFields);
                        if (disableButton !== false) {
                            setDisableButton(false);
                        }
                    }}
                >
                    <Skeleton
                        loading={loadingSchedule ? true : false}
                        active={true}
                        paragraph={true}
                    />

                    <Row gutter={[8, 0]} className="schedule-form__container">
                        <Col span={12}>
                            <Form.Item
                                name="monday"
                                valuePropName="checked"
                                hidden={loadingSchedule}
                                dependencies={['mondayEnd', 'mondayStart']}
                            >
                                <Checkbox
                                    onChange={(e) =>
                                        changeCheck(e, 'showMonday')
                                    }
                                >
                                    Lunes
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    {showTime.showMonday !== null &&
                        showTime.showMonday === true && (
                            <Row
                                gutter={[8, 0]}
                                className="schedule-form__container-hours"
                            >
                                <Col span={12} className="schedule-form__time">
                                    <Form.Item
                                        name="mondayStart"
                                        label="Apertura"
                                        hidden={loadingSchedule}
                                        dependencies={['monday']}
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (
                                                        getFieldValue(
                                                            'monday'
                                                        ) === true &&
                                                        value !== undefined &&
                                                        getFieldValue(
                                                            'monday'
                                                        ) === true &&
                                                        value !== null
                                                    ) {
                                                        return Promise.resolve();
                                                    }

                                                    if (
                                                        getFieldValue(
                                                            'monday'
                                                        ) === false ||
                                                        getFieldValue(
                                                            'monday'
                                                        ) === undefined
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        'Debe seleccionar la hora de Apertura'
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <TimePicker
                                            placeholder="Hora"
                                            format={format}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col
                                    span={12}
                                    className="schedule-form__time"
                                    hidden={loadingSchedule}
                                >
                                    <Form.Item
                                        name="mondayEnd"
                                        label="Cierre"
                                        dependencies={['monday']}
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (
                                                        getFieldValue(
                                                            'monday'
                                                        ) === true &&
                                                        value !== undefined &&
                                                        getFieldValue(
                                                            'monday'
                                                        ) === true &&
                                                        value !== null
                                                    ) {
                                                        return Promise.resolve();
                                                    }

                                                    if (
                                                        getFieldValue(
                                                            'monday'
                                                        ) === false ||
                                                        getFieldValue(
                                                            'monday'
                                                        ) === undefined
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        'Debe seleccionar la hora de Cierre'
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <TimePicker
                                            placeholder="Hora"
                                            format={format}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}

                    <Row gutter={[8, 0]} className="schedule-form__container">
                        <Col span={12}>
                            <Form.Item
                                name="tuesday"
                                valuePropName="checked"
                                hidden={loadingSchedule}
                                dependencies={['tuesdayEnd', 'tuesdayStart']}
                            >
                                <Checkbox
                                    onChange={(e) =>
                                        changeCheck(e, 'showTuesday')
                                    }
                                >
                                    Martes
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    {showTime.showTuesday !== null &&
                        showTime.showTuesday === true && (
                            <Row
                                gutter={[8, 0]}
                                className="schedule-form__container-hours"
                            >
                                <Col span={12} className="schedule-form__time">
                                    <Form.Item
                                        name="tuesdayStart"
                                        label="Apertura"
                                        hidden={loadingSchedule}
                                        dependencies={['tuesday']}
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (
                                                        getFieldValue(
                                                            'tuesday'
                                                        ) === true &&
                                                        value !== undefined &&
                                                        getFieldValue(
                                                            'tuesday'
                                                        ) === true &&
                                                        value !== null
                                                    ) {
                                                        return Promise.resolve();
                                                    }

                                                    if (
                                                        getFieldValue(
                                                            'tuesday'
                                                        ) === false ||
                                                        getFieldValue(
                                                            'tuesday'
                                                        ) === undefined
                                                    ) {
                                                        return Promise.resolve();
                                                    }

                                                    return Promise.reject(
                                                        'Debe seleccionar la hora de Apertura'
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <TimePicker
                                            placeholder="Hora"
                                            format={format}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12} className="schedule-form__time">
                                    <Form.Item
                                        name="tuesdayEnd"
                                        label="Cierre"
                                        hidden={loadingSchedule}
                                        dependencies={['tuesday']}
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (
                                                        getFieldValue(
                                                            'tuesday'
                                                        ) === true &&
                                                        value !== undefined &&
                                                        getFieldValue(
                                                            'tuesday'
                                                        ) === true &&
                                                        value !== null
                                                    ) {
                                                        return Promise.resolve();
                                                    }

                                                    if (
                                                        getFieldValue(
                                                            'tuesday'
                                                        ) === false ||
                                                        getFieldValue(
                                                            'tuesday'
                                                        ) === undefined
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        'Debe seleccionar la hora de Cierre'
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <TimePicker
                                            placeholder="Hora"
                                            format={format}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}

                    <Row gutter={[8, 0]} className="schedule-form__container">
                        <Col span={12}>
                            <Form.Item
                                name="wednesday"
                                valuePropName="checked"
                                dependencies={[
                                    'wednesdayEnd',
                                    'wednesdayStart',
                                ]}
                                hidden={loadingSchedule}
                            >
                                <Checkbox
                                    onChange={(e) =>
                                        changeCheck(e, 'showWednesday')
                                    }
                                >
                                    Miércoles
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    {showTime.showWednesday !== null &&
                        showTime.showWednesday === true && (
                            <Row
                                gutter={[8, 0]}
                                className="schedule-form__container-hours"
                            >
                                <Col span={12} className="schedule-form__time">
                                    <Form.Item
                                        name="wednesdayStart"
                                        label="Apertura"
                                        hidden={loadingSchedule}
                                        dependencies={['wednesday']}
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (
                                                        getFieldValue(
                                                            'wednesday'
                                                        ) === true &&
                                                        value !== undefined &&
                                                        getFieldValue(
                                                            'wednesday'
                                                        ) === true &&
                                                        value !== null
                                                    ) {
                                                        return Promise.resolve();
                                                    }

                                                    if (
                                                        getFieldValue(
                                                            'wednesday'
                                                        ) === false ||
                                                        getFieldValue(
                                                            'wednesday'
                                                        ) === undefined
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        'Debe seleccionar la hora de Apertura'
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <TimePicker
                                            placeholder="Hora"
                                            format={format}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col
                                    span={12}
                                    className="schedule-form__time"
                                    hidden={loadingSchedule}
                                >
                                    <Form.Item
                                        name="wednesdayEnd"
                                        label="Cierre"
                                        hidden={loadingSchedule}
                                        dependencies={['wednesday']}
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (
                                                        getFieldValue(
                                                            'wednesday'
                                                        ) === true &&
                                                        value !== undefined &&
                                                        getFieldValue(
                                                            'wednesday'
                                                        ) === true &&
                                                        value !== null
                                                    ) {
                                                        return Promise.resolve();
                                                    }

                                                    if (
                                                        getFieldValue(
                                                            'wednesday'
                                                        ) === false ||
                                                        getFieldValue(
                                                            'wednesday'
                                                        ) === undefined
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        'Debe seleccionar la hora de Cierre'
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <TimePicker
                                            placeholder="Hora"
                                            format={format}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}

                    <Row gutter={[8, 0]} className="schedule-form__container">
                        <Col span={12}>
                            <Form.Item
                                name="thursday"
                                valuePropName="checked"
                                hidden={loadingSchedule}
                                dependencies={['thursdayEnd', 'thursdayStart']}
                            >
                                <Checkbox
                                    onChange={(e) =>
                                        changeCheck(e, 'showThursday')
                                    }
                                >
                                    Jueves
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    {showTime.showThursday !== null &&
                        showTime.showThursday === true && (
                            <Row
                                gutter={[8, 0]}
                                className="schedule-form__container-hours"
                            >
                                <Col span={12} className="schedule-form__time">
                                    <Form.Item
                                        name="thursdayStart"
                                        label="Apertura"
                                        hidden={loadingSchedule}
                                        dependencies={['thursday']}
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (
                                                        getFieldValue(
                                                            'thursday'
                                                        ) === true &&
                                                        value !== undefined &&
                                                        getFieldValue(
                                                            'thursday'
                                                        ) === true &&
                                                        value !== null
                                                    ) {
                                                        return Promise.resolve();
                                                    }

                                                    if (
                                                        getFieldValue(
                                                            'thursday'
                                                        ) === false ||
                                                        getFieldValue(
                                                            'thursday'
                                                        ) === undefined
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        'Debe seleccionar la hora de Apertura'
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <TimePicker
                                            placeholder="Hora"
                                            format={format}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col
                                    span={12}
                                    className="schedule-form__time"
                                    hidden={loadingSchedule}
                                >
                                    <Form.Item
                                        name="thursdayEnd"
                                        label="Cierre"
                                        dependencies={['thursday']}
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (
                                                        getFieldValue(
                                                            'thursday'
                                                        ) === true &&
                                                        value !== undefined &&
                                                        getFieldValue(
                                                            'thursday'
                                                        ) === true &&
                                                        value !== null
                                                    ) {
                                                        return Promise.resolve();
                                                    }

                                                    if (
                                                        getFieldValue(
                                                            'thursday'
                                                        ) === false ||
                                                        getFieldValue(
                                                            'thursday'
                                                        ) === undefined
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        'Debe seleccionar la hora de Cierre'
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <TimePicker
                                            placeholder="Hora"
                                            format={format}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}

                    <Row gutter={[8, 0]} className="schedule-form__container">
                        <Col span={12}>
                            <Form.Item
                                name="friday"
                                valuePropName="checked"
                                hidden={loadingSchedule}
                                dependencies={['fridayEnd', 'fridayStart']}
                            >
                                <Checkbox
                                    onChange={(e) =>
                                        changeCheck(e, 'showFriday')
                                    }
                                >
                                    Viernes
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    {showTime.showFriday !== null &&
                        showTime.showFriday === true && (
                            <Row
                                gutter={[8, 0]}
                                className="schedule-form__container-hours"
                            >
                                <Col span={12} className="schedule-form__time">
                                    <Form.Item
                                        name="fridayStart"
                                        label="Apertura"
                                        hidden={loadingSchedule}
                                        dependencies={['friday']}
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (
                                                        getFieldValue(
                                                            'friday'
                                                        ) === true &&
                                                        value !== undefined &&
                                                        getFieldValue(
                                                            'friday'
                                                        ) === true &&
                                                        value !== null
                                                    ) {
                                                        return Promise.resolve();
                                                    }

                                                    if (
                                                        getFieldValue(
                                                            'friday'
                                                        ) === false ||
                                                        getFieldValue(
                                                            'friday'
                                                        ) === undefined
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        'Debe seleccionar la hora de Apertura'
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <TimePicker
                                            placeholder="Hora"
                                            format={format}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col
                                    span={12}
                                    className="schedule-form__time"
                                    hidden={loadingSchedule}
                                >
                                    <Form.Item
                                        name="fridayEnd"
                                        label="Cierre"
                                        dependencies={['friday']}
                                        hidden={loadingSchedule}
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (
                                                        getFieldValue(
                                                            'friday'
                                                        ) === true &&
                                                        value !== undefined &&
                                                        getFieldValue(
                                                            'friday'
                                                        ) === true &&
                                                        value !== null
                                                    ) {
                                                        return Promise.resolve();
                                                    }

                                                    if (
                                                        getFieldValue(
                                                            'friday'
                                                        ) === false ||
                                                        getFieldValue(
                                                            'friday'
                                                        ) === undefined
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        'Debe seleccionar la hora de Cierre'
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <TimePicker
                                            placeholder="Hora"
                                            format={format}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}

                    <Row gutter={[8, 0]} className="schedule-form__container">
                        <Col span={12}>
                            <Form.Item
                                name="saturday"
                                valuePropName="checked"
                                hidden={loadingSchedule}
                                dependencies={['saturdayEnd', 'saturdayStart']}
                            >
                                <Checkbox
                                    onChange={(e) =>
                                        changeCheck(e, 'showSaturday')
                                    }
                                >
                                    Sábado
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    {showTime.showSaturday !== null &&
                        showTime.showSaturday === true && (
                            <Row
                                gutter={[8, 0]}
                                className="schedule-form__container-hours"
                            >
                                <Col span={12} className="schedule-form__time">
                                    <Form.Item
                                        name="saturdayStart"
                                        label="Apertura"
                                        hidden={loadingSchedule}
                                        dependencies={['saturday']}
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (
                                                        getFieldValue(
                                                            'saturday'
                                                        ) === true &&
                                                        value !== undefined &&
                                                        getFieldValue(
                                                            'saturday'
                                                        ) === true &&
                                                        value !== null
                                                    ) {
                                                        return Promise.resolve();
                                                    }

                                                    if (
                                                        getFieldValue(
                                                            'saturday'
                                                        ) === false ||
                                                        getFieldValue(
                                                            'saturday'
                                                        ) === undefined
                                                    ) {
                                                        return Promise.resolve();
                                                    }

                                                    return Promise.reject(
                                                        'Debe seleccionar la hora de Apertura'
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <TimePicker
                                            placeholder="Hora"
                                            format={format}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12} className="schedule-form__time">
                                    <Form.Item
                                        name="saturdayEnd"
                                        label="Cierre"
                                        hidden={loadingSchedule}
                                        dependencies={['saturday']}
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (
                                                        getFieldValue(
                                                            'saturday'
                                                        ) === true &&
                                                        value !== undefined &&
                                                        getFieldValue(
                                                            'saturday'
                                                        ) === true &&
                                                        value !== null
                                                    ) {
                                                        return Promise.resolve();
                                                    }

                                                    if (
                                                        getFieldValue(
                                                            'saturday'
                                                        ) === false ||
                                                        getFieldValue(
                                                            'saturday'
                                                        ) === undefined
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        'Debe seleccionar la hora de Cierre'
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <TimePicker
                                            placeholder="Hora"
                                            format={format}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}

                    <Row gutter={[8, 0]} className="schedule-form__container">
                        <Col span={12}>
                            <Form.Item
                                name="sunday"
                                valuePropName="checked"
                                hidden={loadingSchedule}
                                dependencies={['sundayEnd', 'sundayStart']}
                            >
                                <Checkbox
                                    onChange={(e) =>
                                        changeCheck(e, 'showSunday')
                                    }
                                >
                                    Domingo
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>

                    {showTime.showSunday !== null &&
                        showTime.showSunday === true && (
                            <Row
                                gutter={[8, 0]}
                                className="schedule-form__container-hours"
                            >
                                <Col span={12} className="schedule-form__time">
                                    <Form.Item
                                        name="sundayStart"
                                        label="Apertura"
                                        hidden={loadingSchedule}
                                        dependencies={['sunday']}
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (
                                                        getFieldValue(
                                                            'sunday'
                                                        ) === true &&
                                                        value !== undefined &&
                                                        getFieldValue(
                                                            'sunday'
                                                        ) === true &&
                                                        value !== null
                                                    ) {
                                                        return Promise.resolve();
                                                    }

                                                    if (
                                                        getFieldValue(
                                                            'sunday'
                                                        ) === false ||
                                                        getFieldValue(
                                                            'sunday'
                                                        ) === undefined
                                                    ) {
                                                        return Promise.resolve();
                                                    }

                                                    return Promise.reject(
                                                        'Debe seleccionar la hora de Apertura'
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <TimePicker
                                            placeholder="Hora"
                                            format={format}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12} className="schedule-form__time">
                                    <Form.Item
                                        name="sundayEnd"
                                        label="Cierre"
                                        hidden={loadingSchedule}
                                        dependencies={['sunday']}
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (
                                                        getFieldValue(
                                                            'sunday'
                                                        ) === true &&
                                                        value !== undefined &&
                                                        getFieldValue(
                                                            'sunday'
                                                        ) === true &&
                                                        value !== null
                                                    ) {
                                                        return Promise.resolve();
                                                    }

                                                    if (
                                                        getFieldValue(
                                                            'sunday'
                                                        ) === false ||
                                                        getFieldValue(
                                                            'sunday'
                                                        ) === undefined
                                                    ) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        'Debe seleccionar la hora de Cierre'
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <TimePicker
                                            placeholder="Hora"
                                            format={format}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}

                    <Form.Item className="schedule-form__container">
                        <Button
                            type="primary"
                            htmlType="submit"
                            block={true}
                            size="large"
                            disabled={saving ? true : false}
                        >
                            Guardar Horario
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
}
