import React, { Fragment, useEffect, useState, useRef } from 'react';
import {
    Typography,
    Skeleton,
    Divider,
    Button,
    Avatar,
    Image,
    List,
    Tabs,
    Card,
    Row,
    Col,
    Spin,
    Empty,
    notification,
    message,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import food from '../../../assets/img/jpg/seafood.jpg';
import { getAccessTokenApi } from '../../../api/auth';
import { getCompleteMenuClient, getMenuGroupApi } from '../../../api/menu';
import Modal from '../../../components/General/Modal';
import DescriptionItem from '../../../components/Client/DescriptionItem';
import BasketForm from '../../../components/Client/BasketForm';
import { formatMoney } from '../../../utils/general';
import useOrder from '../../../hooks/useOrder';

import './MenuCustomer.scss';

const MenuCustomer = (props) => {
    const isMounted = useRef(true);
    const { TabPane } = Tabs;
    const [group, setGroup] = useState();
    const [loading, setLoading] = useState(true);
    const [loadingItems, setLoadingItems] = useState(false);
    const { Title } = Typography;
    const { infoCompany, scheduleCompany } = props;
    const { orderGeneral } = useOrder();
    const { Subtotal, orderLinesList, CompanyId, OrderType } = orderGeneral;
    const token = getAccessTokenApi();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const [titleModal, setTitleModal] = useState('');
    const [counterItem, setCounterItem] = useState(0);
    const dayNumber = moment().day() === 0 ? 7 : moment().day();
    const [groupSearches, setGroupSearches] = useState([]);
    const [activeKey, setActiveKey] = useState('');

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        setCounterItem(orderLinesList.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderGeneral]);

    useEffect(() => {
        if (CompanyId === '' || OrderType === '') {
            return false;
        }
        const data = {
            CompanyId: CompanyId,
            OrderType: OrderType,
            MenuClient: true,
        };

        getCompleteMenuClient(token, data).then((group) => {
            if (isMounted.current) {
                if (group !== undefined) {
                    if (group.length > 0) {
                        let idGroupOne = group[0]['groupId'];

                        const dataApi = {
                            CompanyId: CompanyId,
                            GroupId: idGroupOne,
                            OrderType: OrderType,
                            MenuClient: true,
                        };
                        getMenuGroupApi(token, dataApi).then((resp) => {
                            group[0] = resp;
                            setActiveKey(idGroupOne.toString());
                            setGroup(group);
                            setLoading(false);
                            setGroupSearches([idGroupOne]);
                        });
                    } else {
                        setGroup([]);
                        setLoading(false);
                    }
                } else {
                    notification['error']({
                        message: 'No cuenta conexión a internet',
                    });
                }
            }
        });

        return () => {
            setGroup([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CompanyId, OrderType]);

    const callbackGroup = (key) => {
        let idGroup = parseInt(key);
        const foundIdGroup = groupSearches.find((id) => id === idGroup);
        if (foundIdGroup === undefined) {
            setLoadingItems(true);
            let dataApi = {
                CompanyId: orderGeneral.CompanyId,
                GroupId: idGroup,
                OrderType: orderGeneral.OrderType,
                MenuClient: true,
            };
            getMenuGroupApi(token, dataApi).then((resp) => {
                if (isMounted.current) {
                    if (resp !== undefined) {
                        setGroupSearches([...groupSearches, idGroup]);
                        const found = group.findIndex(
                            (item) => item.groupId === resp.groupId
                        );
                        group[found] = resp;
                        setGroup(group);
                        setActiveKey(idGroup.toString());
                        setLoadingItems(false);
                    }

                    if (resp === undefined) {
                        message.error('No cuenta con conexion a internet');
                        setActiveKey(idGroup.toString());
                        setLoadingItems(false);
                    }
                } else {
                    console.log('NO HARAS NADA');
                }
            });
        } else {
            setActiveKey(key);
        }
    };

    const listData = [];
    for (let i = 0; i < 5; i++) {
        listData.push({
            href: 'https://ant.design',
            title: `menuClient ${i}`,
            avatar:
                'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
            description:
                'Ant Design, a design language for background applications, is refined by Ant UED Team.',
            content:
                'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
        });
    }

    const showModal = (item, title) => {
        if (infoCompany !== undefined) {
            if (infoCompany.ActiveOrder !== undefined) {
                if (infoCompany.ActiveOrder) {
                    notification['info']({
                        message: 'Ya tienes una orden en proceso',
                        description:
                            'Debes finalizar una orden para poder realizar una nueva',
                    });
                    return false;
                }
            }
        }

        setIsModalVisible(true);
        if (title === 'Order') {
            setContextModal(
                <BasketForm
                    setCounterItem={setCounterItem}
                    setIsModalVisible={setIsModalVisible}
                    infoCompany={infoCompany}
                    // setTotalOrder={setTotalOrder}
                    // order={orderGeneral}
                />
            );
            setTitleModal('Orden');
        } else {
            if (scheduleCompany !== null) {
                if (scheduleCompany.Schedule.length === 0) {
                    let msg = `El restaurante esta temporalmente cerrado.`;
                    message.warning(msg, 3);
                    setIsModalVisible(false);
                    return false;
                }
            } else {
                return;
            }

            const validateSchedule = validSchedule();

            if (validateSchedule.isClose) {
                let msg = `Lo sentimos el establecimiento esta cerrado este día. ${validateSchedule.message}`;
                message.warning(msg, 3);
                setIsModalVisible(false);
                return false;
            }

            if (!validateSchedule.isClose && !validateSchedule.isBetween) {
                let msg = `Lo sentimos el establecimiento esta cerrado.  ${validateSchedule.message}`;
                message.warning(msg, 3);
                setIsModalVisible(false);
                return false;
            }

            setContextModal(
                <DescriptionItem
                    item={item}
                    setIsModalVisible={setIsModalVisible}
                    // setCounterItem={setCounterItem}
                    // order={order}
                    // setOrder={setOrder}
                    // setTotalOrder={setTotalOrder}
                    // totalOrder={totalOrder}
                />
            );
            setTitleModal(title);
        }
    };

    const validSchedule = () => {
        let now = moment().format('YYYY-MM-DD');
        const dayFound = scheduleCompany.Schedule.find(
            (element) => element.dayOfWeek === dayNumber
        );

        // let nextDay = 0;
        let response = {
            isClose: true,
            isBetween: false,
            nextDay: 0,
            message: '',
        };

        if (dayNumber >= 1 && dayNumber <= 6) {
            response.nextDay = dayNumber + 1;
        } else {
            response.nextDay = 1;
        }

        if (dayFound !== undefined) {
            //is open
            let timeDay = {
                timeStart: moment(`${now} ${dayFound.timeStart}`),
                timeEnd: moment(`${now} ${dayFound.timeEnd}`),
            };

            if (moment(timeDay.timeEnd).isBefore(timeDay.timeStart)) {
                //LA HORA FINAL ES ANTES QUE LA FINAL
                //SUMARLE UN DIA
                timeDay.timeEnd = timeDay.timeEnd.add(1, 'd');
            }

            let isBetween = moment().isBetween(
                timeDay.timeStart,
                timeDay.timeEnd
            ); // true

            response.isClose = false;
            response.isBetween = isBetween;

            if (!isBetween) {
                const isBeforeStart = moment().isBefore(timeDay.timeStart);

                if (isBeforeStart) {
                    response.message = `Apertura: ${timeDay.timeStart.format(
                        'h:mm A'
                    )} a ${timeDay.timeEnd.format('h:mm A')} `;
                } else {
                    response = NextOpening(response, now);
                }
            }
        } else {
            //is not open this day
            response = NextOpening(response, now);
        }

        return response;
    };

    const NextOpening = (response, now) => {
        let findNextDay = false;

        while (!findNextDay) {
            const dayNextFound = scheduleCompany.Schedule.find(
                (element) => element.dayOfWeek === response.nextDay
            );

            if (dayNextFound === undefined) {
                if (response.nextDay >= 1 && response.nextDay <= 6) {
                    response.nextDay = response.nextDay + 1;
                } else {
                    response.nextDay = 1;
                }
            } else {
                response.nextDayTimeStart = moment(
                    `${now} ${dayNextFound.timeStart}`
                );
                response.nextDayTimeEnd = moment(
                    `${now} ${dayNextFound.timeStart}`
                );

                let dayString = '';
                switch (dayNextFound.dayOfWeek) {
                    case 1:
                        dayString = 'Lunes';
                        break;
                    case 2:
                        dayString = 'Martes';
                        break;
                    case 3:
                        dayString = 'Miércoles';
                        break;
                    case 4:
                        dayString = 'Jueves';
                        break;
                    case 5:
                        dayString = 'Viernes';
                        break;
                    case 6:
                        dayString = 'Sábado';
                        break;
                    case 7:
                        dayString = 'Domingo';
                        break;

                    default:
                        break;
                }
                response.message = `Próxima apertura: ${dayString} de ${moment(
                    `${now} ${dayNextFound.timeStart}`
                ).format('h:mm A')} a ${moment(
                    `${now} ${dayNextFound.timeEnd}`
                ).format('h:mm A')} `;
                findNextDay = true;
            }
        }

        return response;
    };

    return (
        <>
            {loading === false && (
                <>
                    <Spin spinning={loadingItems} tip="Cargando...">
                        <Tabs
                            activeKey={activeKey}
                            defaultActiveKey={activeKey}
                            centered
                            className={
                                'menuCustomer ' +
                                (counterItem > 0 ? 'active-scroll' : '')
                            }
                            onChange={callbackGroup}
                        >
                            {group.length > 0 &&
                                group.map((group) => {
                                    return (
                                        <Fragment key={'group' + group.groupId}>
                                            {group.categories.length > 0 && (
                                                <TabPane
                                                    tab={group.name}
                                                    key={group.groupId}
                                                >
                                                    {group.categories.length >
                                                        0 &&
                                                        group.categories.map(
                                                            (cat) => {
                                                                return (
                                                                    <Fragment
                                                                        key={
                                                                            cat.categoryId
                                                                        }
                                                                    >
                                                                        {cat
                                                                            .items
                                                                            .length >
                                                                            0 && (
                                                                            <Divider
                                                                                orientation="left"
                                                                                plain
                                                                            >
                                                                                <Title
                                                                                    level={
                                                                                        4
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        cat.name
                                                                                    }
                                                                                </Title>
                                                                            </Divider>
                                                                        )}

                                                                        {cat
                                                                            .items
                                                                            .length >
                                                                            0 &&
                                                                            cat.items.map(
                                                                                (
                                                                                    item
                                                                                ) => {
                                                                                    return (
                                                                                        <Card
                                                                                            className="card-dish"
                                                                                            key={
                                                                                                item.itemId
                                                                                            }
                                                                                        >
                                                                                            <Row>
                                                                                                <Col
                                                                                                    span={
                                                                                                        14
                                                                                                    }
                                                                                                >
                                                                                                    <Title
                                                                                                        level={
                                                                                                            4
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            item.name
                                                                                                        }
                                                                                                    </Title>
                                                                                                    {!item.available && (
                                                                                                        <p className="card-dish__noAvailable">
                                                                                                            No
                                                                                                            está
                                                                                                            disponible
                                                                                                        </p>
                                                                                                    )}
                                                                                                    <p className="card-dish__description">
                                                                                                        {
                                                                                                            item.description
                                                                                                        }
                                                                                                    </p>
                                                                                                    <p>
                                                                                                        {' '}
                                                                                                        $
                                                                                                        {formatMoney(
                                                                                                            item.price
                                                                                                        )}
                                                                                                    </p>
                                                                                                    <Button
                                                                                                        icon={
                                                                                                            <PlusOutlined />
                                                                                                        }
                                                                                                        danger
                                                                                                        onClick={() =>
                                                                                                            showModal(
                                                                                                                item,
                                                                                                                ''
                                                                                                            )
                                                                                                        }
                                                                                                        disabled={
                                                                                                            item.available
                                                                                                                ? false
                                                                                                                : true
                                                                                                        }
                                                                                                    >
                                                                                                        Agregar
                                                                                                    </Button>
                                                                                                </Col>
                                                                                                <Col
                                                                                                    span={
                                                                                                        10
                                                                                                    }
                                                                                                    className="text-right"
                                                                                                >
                                                                                                    {item.itemImage !==
                                                                                                        null && (
                                                                                                        <Image
                                                                                                            width={
                                                                                                                120
                                                                                                            }
                                                                                                            src={
                                                                                                                item.itemImage !==
                                                                                                                null
                                                                                                                    ? `data:image/${item.itemImage.extension};base64, ${item.itemImage.imageData}`
                                                                                                                    : food
                                                                                                            }
                                                                                                        />
                                                                                                    )}
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Card>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        {/* </Spin> */}
                                                                    </Fragment>
                                                                );
                                                            }
                                                        )}
                                                </TabPane>
                                            )}
                                        </Fragment>
                                    );
                                })}
                        </Tabs>
                        {group.length === 0 && (
                            <Empty description={'No hay platillos'} />
                        )}
                    </Spin>
                    {counterItem > 0 && (
                        <div className="menu-costumer-button">
                            <Button
                                type="primary"
                                block
                                danger
                                size="large"
                                onClick={() => showModal(undefined, 'Order')}
                            >
                                <Row>
                                    <Col
                                        span={4}
                                        className="menu-costumer-button__left"
                                    >
                                        <div className="counter-square">
                                            {counterItem}
                                        </div>
                                    </Col>

                                    <Col
                                        span={10}
                                        className="menu-costumer-button__center"
                                    >
                                        Ver Canasta
                                    </Col>

                                    <Col
                                        span={10}
                                        className="menu-costumer-button__right"
                                    >
                                        <p>Total ${formatMoney(Subtotal)}</p>
                                        {/* <p>Total ${formatMoney(totalOrder)}</p> */}
                                    </Col>
                                </Row>
                            </Button>
                        </div>
                    )}
                </>
            )}

            {loading === true && (
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={listData}
                    renderItem={(item) => (
                        <List.Item key={item.title}>
                            <Skeleton loading={loading} active avatar>
                                <List.Item.Meta
                                    avatar={<Avatar src={item.avatar} />}
                                    title={<a href={item.href}>{item.title}</a>}
                                    description={item.description}
                                />
                                {item.content}
                            </Skeleton>
                        </List.Item>
                    )}
                />
            )}

            <Modal
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                title={titleModal}
                footer={false}
                destroyOnClose
            >
                {contextModal}
            </Modal>
        </>
    );
};

export default MenuCustomer;
